import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss'],
})
export class HeaderTitleComponent {
  @Input() header: string;
  @Input() backgroundColor = '#EBF7FA';
  @Input() textColor = '#6FB2E3';
  constructor() {}
}
