import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerUserRoutingModule } from './customer-user-routing.module';
import { CustomerUserComponent } from './components/customer-user/customer-user.component';
import { AngularResizedEventModule } from 'angular-resize-event';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  NgbDateParserFormatter,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CustomerDetailComponent } from './components/customer-detail/customer-detail.component';
import { CustomerAddComponent } from './components/customer-add/customer-add.component';
import { CustomerEditComponent } from './components/customer-edit/customer-edit.component';
import { IMaskModule } from 'angular-imask';
import { NgbThaiDateParserFormatter } from '../memos/service/NgDateParser';

@NgModule({
  declarations: [
    CustomerUserComponent,
    CustomerDetailComponent,
    CustomerAddComponent,
    CustomerEditComponent,
  ],
  imports: [
    CommonModule,
    CustomerUserRoutingModule,
    AngularResizedEventModule,
    SharedModule,
    NgbModule,
    ImageCropperModule,
    IMaskModule,
  ],
  providers: [
    NgbThaiDateParserFormatter,
    {
      provide: NgbDateParserFormatter,
      useClass: NgbThaiDateParserFormatter,
    },
  ],
})
export class CustomerUserModule {}
