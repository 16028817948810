import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { AlertService } from 'src/app/core/services/alert.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { OperationLog } from 'src/app/modules/operation-log/shared/operation-log.models';
import {
  LegalEntity,
  NaturalPerson,
} from '../../model/customer-user.model';
import { CustomerUserService } from '../../services/customer-user.service';
import * as moment from 'moment';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';

type TabName = {
  name: string;
  hasNotification: boolean;
  translateLabel: string;
  buttonLabel: string;
};

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss'],
})
export class CustomerDetailComponent implements OnInit, OnDestroy {
  showPreview = false;
  showResult = true;
  documentData: any;
  onEdit = false;
  eventStatus;
  modal: NgbModalRef;
  userID = localStorage.getItem('profile_id');
  departmentList = { th: '', en: '' };
  // Tooltip
  toolTipDisable = '';
  toolTipDelete = '';
  individualId: number;
  companyId: number;
  individual: NaturalPerson;
  company: LegalEntity;
  typeCheckDetail = '';
  chevronLeft = faChevronLeft;
  selectionTabItems: TabName[] = [
    {
      name: 'Detail',
      hasNotification: false,
      translateLabel: 'MANAGE-CUSTOMER.DETAIL',
      buttonLabel: 'MANAGE-CUSTOMER.DETAIL',
    },
    {
      name: 'Detail-Company',
      hasNotification: false,
      translateLabel: 'MANAGE-CUSTOMER.DETAIL-COMPANY',
      buttonLabel: 'MANAGE-CUSTOMER.DETAIL-COMPANY',
    },
    {
      name: 'History',
      hasNotification: false,
      translateLabel: 'MANAGE-CUSTOMER.HISTORY',
      buttonLabel: 'MANAGE-CUSTOMER.HISTORY',
    },
    {
      name: 'Rental-Log',
      hasNotification: false,
      translateLabel: 'MANAGE-CUSTOMER.RENTAL-LOG',
      buttonLabel: 'MANAGE-CUSTOMER.RENTAL-LOG',
    },
  ];
  activeSelectionTabItem: TabName;
  isIndividual = false;
  isCompany = false;
  person;
  operation_logs: Array<OperationLog> = [];
  itemsPerPage = 10;
  count: number;
  currentPage = 1;
  reason: string;
  isRepeatEmail = false;

  @Select((state) => state.auth?.role)
  curUserRole$: Observable<string>;
  memoPreviewPath = '/memos/preview';
  destroy$ = new Subject();

  role: string;
  @Output() toggleEdit = new EventEmitter();
  @Output() actionChanged = new EventEmitter();
  @Output() saveDelete = new EventEmitter();
  @Output() closePage = new EventEmitter();
  @Output() enableUser = new EventEmitter();
  constructor(
    private modalService: NgbModal,
    private alert: AlertService,
    private spinner: SpinnerService,
    public translate: TranslateService,
    private activateRoute: ActivatedRoute,
    private customerService: CustomerUserService,
    private router: Router,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.role = this.store.selectSnapshot<string>((s) => s.auth.role);
    this.activateRoute.queryParams.subscribe((res) => {
      this.individual = null;
      this.company = null;
      this.typeCheckDetail = res.type;
      if (res.type === 'individual') {
        this.activeSelectionTabItem = this.selectionTabItems[0];
        this.individualId = res.id;
        if (res.id) {
          this.getIndividualDetail();
          this.getInformationLog(
            'individualperson',
            res.id,
            res.person,
          );
        }
      } else {
        this.activeSelectionTabItem = this.selectionTabItems[1];
        this.companyId = res.id;
        if (res.id) {
          this.getCompanyDetail();
          this.getInformationLog('company', res.id, res.person);
        }
      }
    });

    this.curUserRole$
      .pipe(takeUntil(this.destroy$))
      .subscribe((role) => {
        if (role && role.toLowerCase().includes('admin')) {
          this.memoPreviewPath = '/memos/trash/preview';
        } else {
          this.memoPreviewPath = '/memos/preview';
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getIndividualDetail(): void {
    this.spinner.show();
    this.showResult = false;
    this.onEdit = false;
    this.customerService
      .getIndividual(this.individualId)
      .pipe(
        finalize(() => {
          this.showResult = true;
          this.spinner.hide();
        }),
      )
      .subscribe(
        (res: NaturalPerson) => {
          this.individual = res;
          if (this.individual.person) {
            this.getPerson(this.individual.person);
          } else {
            this.person = null;
          }
        },
        () => {
          this.alert.error('Unable to load profile');
        },
      );
  }

  getCompanyDetail(): void {
    this.spinner.show();
    this.showResult = false;
    this.onEdit = false;
    this.customerService
      .getCompany(this.companyId)
      .pipe(
        finalize(() => {
          this.showResult = true;
          this.spinner.hide();
        }),
      )
      .subscribe(
        (res: LegalEntity) => {
          this.company = res;
          if (this.company.person) {
            this.getPerson(this.company.person);
          } else {
            this.person = null;
          }
        },
        () => {
          this.alert.error('Unable to load profile');
        },
      );
  }

  getCheckDataBeforeApprove(payload) {
    const msg = [];
    if (!payload?.location) {
      msg.push(
        this.translate.instant('MANAGE-CUSTOMER.VALID-LOCATIN'),
      );
    }
    if (!payload?.property) {
      msg.push(
        this.translate.instant('MANAGE-CUSTOMER.VALID-TYPE-PROPERTY'),
      );
    }
    if (!payload?.building) {
      msg.push(
        this.translate.instant('MANAGE-CUSTOMER.VALID-BUIDLING'),
      );
    }

    return msg;
  }

  getPerson(event): void {
    const params = {
      show_rental_log: true,
    };

    this.customerService
      .getCustomerPersonDetail(event, params)
      .subscribe(
        (res) => {
          this.person = res;
        },
        () => {
          this.alert.error('Unable to load profile');
        },
      );
  }

  getInformationLog(type: string, id: any, person_id: any): void {
    const params = {
      customer_type: type,
      related_id: id,
      person_id: person_id ? person_id : '',
    };
    this.customerService
      .getCustomerOperationLogList(params)
      .subscribe(
        (logs) => {
          this.operation_logs = logs.results;
          this.count = logs.count;
        },
        (error) => {
          this.alert.error(
            'System error, Please contact technical support.',
          );
        },
      );
  }

  editProfile(): void {
    this.onEdit = true;
  }

  confirmSwitchTab(event) {
    this.activeSelectionTabItem = event;
  }

  openUpdate(event, type, accessible): void {
    this.eventStatus = event;
    this.modal = this.modalService.open(accessible, {
      centered: true,
    });
  }

  closeModal(): void {
    this.modalService.dismissAll();
    this.reason = '';
  }

  updateAccessible(key, type): void {
    if (type === 'individual') {
      if (key === 'true') {
        this.enableIndividual();
      } else {
        this.disableIndividual();
      }
    } else {
      if (key === 'true') {
        this.enableCompany();
      } else {
        this.disableCompany();
      }
    }
  }

  disableIndividual(): void {
    this.spinner.show();
    this.showResult = false;
    this.customerService
      .disableCustomerPerson(this.individual.person)
      .pipe(
        finalize(() => {
          this.showResult = true;
          this.spinner.hide();
        }),
      )
      .subscribe(
        () => {
          this.alert.success();
          this.getIndividualDetail();
          this.enableUser.emit();
          this.modal.close();
        },
        () => {
          this.alert.error(
            this.translate.instant('ERROR.CONTACT-DEV'),
          );
        },
      );
  }

  enableIndividual(): void {
    this.spinner.show();
    this.showResult = false;
    this.customerService
      .enableCustomerPerson(this.individual.person)
      .pipe(
        finalize(() => {
          this.showResult = true;
          this.spinner.hide();
        }),
      )
      .subscribe(
        () => {
          this.alert.success();
          this.getIndividualDetail();
          this.enableUser.emit();
          this.modal.close();
        },
        () => {
          this.alert.error(
            this.translate.instant('ERROR.CONTACT-DEV'),
          );
        },
      );
  }

  enableCompany(): void {
    this.spinner.show();
    this.showResult = false;
    this.customerService
      .enableCustomerPerson(this.company.person)
      .pipe(
        finalize(() => {
          this.showResult = true;
          this.spinner.hide();
        }),
      )
      .subscribe(
        () => {
          this.alert.success();
          this.getCompanyDetail();
          this.enableUser.emit();
          this.modal.close();
        },
        () => {
          this.alert.error(
            this.translate.instant('ERROR.CONTACT-DEV'),
          );
        },
      );
  }

  disableCompany(): void {
    this.spinner.show();
    this.showResult = false;
    this.customerService
      .disableCustomerPerson(this.company.person)
      .pipe(
        finalize(() => {
          this.showResult = true;
          this.spinner.hide();
        }),
      )
      .subscribe(
        () => {
          this.alert.success();
          this.getCompanyDetail();
          this.enableUser.emit();
          this.modal.close();
        },
        () => {
          this.alert.error(
            this.translate.instant('ERROR.CONTACT-DEV'),
          );
        },
      );
  }

  closeToggle(): void {
    this.actionChanged.emit(true);
  }

  deleteIndividual(data: NaturalPerson): void {
    this.spinner.show();
    this.showResult = false;
    const individualPersonId = this.individual?.person;
    if (individualPersonId) {
      const params = {
        not_transfer: true,
        person: individualPersonId,
      };
      this.customerService
        .deleteCustomerPerson(individualPersonId, params)
        .pipe(
          finalize(() => {
            this.showResult = true;
            this.spinner.hide();
          }),
        )
        .subscribe(
          () => {
            this.saveDelete.emit();
            this.alert.success(
              this.translate.instant('MEMOS.SAVE-CHANGES'),
            );
            this.modalService.dismissAll();
            this.router.navigate(['/customer-user']);
          },
          () => {
            this.alert.error(
              this.translate.instant('ERROR.CONTACT-DEV'),
            );
          },
        );
    } else {
      this.customerService
        .deleteIndividual(data.id)
        .pipe(
          finalize(() => {
            this.showResult = true;
            this.spinner.hide();
          }),
        )
        .subscribe(
          () => {
            this.saveDelete.emit();
            this.alert.success(
              this.translate.instant('MEMOS.SAVE-CHANGES'),
            );
            this.modalService.dismissAll();
            this.router.navigate(['/customer-user']);
          },
          () => {
            this.alert.error(
              this.translate.instant('ERROR.CONTACT-DEV'),
            );
          },
        );
    }
  }

  deleteCompany(data: LegalEntity): void {
    this.spinner.show();
    this.showResult = false;
    const companyPersonId = this.company?.person;
    if (companyPersonId) {
      const params = {
        not_transfer: true,
        person: companyPersonId,
      };
      this.customerService
        .deleteCustomerPerson(companyPersonId, params)
        .pipe(
          finalize(() => {
            this.showResult = true;
            this.spinner.hide();
          }),
        )
        .subscribe(
          () => {
            this.saveDelete.emit();
            this.alert.success(
              this.translate.instant('MEMOS.SAVE-CHANGES'),
            );
            this.modalService.dismissAll();
            this.router.navigate(['/customer-user']);
          },
          () => {
            this.alert.error(
              this.translate.instant('ERROR.CONTACT-DEV'),
            );
          },
        );
    } else {
      this.customerService
        .deleteCompany(data.id)
        .pipe(
          finalize(() => {
            this.showResult = true;
            this.spinner.hide();
          }),
        )
        .subscribe(
          () => {
            this.saveDelete.emit();
            this.alert.success(
              this.translate.instant('MEMOS.SAVE-CHANGES'),
            );
            this.modalService.dismissAll();
            this.router.navigate(['/customer-user']);
          },
          () => {
            this.alert.error(
              this.translate.instant('ERROR.CONTACT-DEV'),
            );
          },
        );
    }
  }

  openModal(otpModal: TemplateRef<any>): void {
    this.modalService.open(otpModal, {
      backdrop: 'static',
      centered: true,
    });
  }

  onClickBack(): void {
    this.closePage.emit(false);
  }

  get currentLang(): string {
    return this.translate.currentLang;
  }

  approvalCompany(payload): void {
    const errorMsg = this.getCheckDataBeforeApprove(payload);
    if (errorMsg.length) {
      this.alert
        .confirm(
          this.translate.instant(
            'MANAGE-CUSTOMER.INCOMPLETE-INFORMATION',
          ),
          this.translate.instant(
            'MANAGE-CUSTOMER.PLEASE-SPECIFY-THE-LOCATION',
          ),
        )
        .then((res) => {
          if (res?.isConfirmed) {
            this.editProfile();
          }
        });
      return;
    }

    const data = {
      id: payload.id,
    };
    this.spinner.show();
    this.showResult = false;
    this.customerService
      .approveStatusCompany('approve', data)
      .pipe(
        finalize(() => {
          this.showResult = true;
          this.spinner.hide();
        }),
      )
      .subscribe(
        (res: any) => {
          this.alert.success(
            this.translate.instant('MEMOS.SAVE-CHANGES'),
          );
          this.saveDelete.emit();
          this.getCompanyDetail();
          this.isRepeatEmail = false;
        },
        (er) => {
          this.isRepeatEmail = true;
          this.alert.error(er.error[0]);
        },
      );
  }

  rejectCompany(event): void {
    const data = {
      id: event,
      reason: this.reason,
    };
    this.spinner.show();
    this.showResult = false;
    this.customerService
      .rejectOrRecoverStatusCompany('reject', data)
      .pipe(
        finalize(() => {
          this.showResult = true;
          this.spinner.hide();
        }),
      )
      .subscribe(
        (res: any) => {
          this.alert.success(
            this.translate.instant('MEMOS.SAVE-CHANGES'),
          );
          this.saveDelete.emit();
          this.getCompanyDetail();
          this.modalService.dismissAll();
          this.closeModal();
        },
        () => {
          this.alert.error('Can not change status');
        },
      );
  }

  recoverCompany(event): void {
    const data = {
      id: event,
    };
    this.spinner.show();
    this.showResult = false;
    this.customerService
      .rejectOrRecoverStatusCompany('recover', data)
      .pipe(
        finalize(() => {
          this.showResult = true;
          this.spinner.hide();
        }),
      )
      .subscribe(
        (res: any) => {
          this.alert.success(
            this.translate.instant('MEMOS.SAVE-CHANGES'),
          );
          this.saveDelete.emit();
          this.getCompanyDetail();
        },
        () => {
          this.alert.error('Can not change status');
        },
      );
  }

  approvalIndividual(payload): void {
    const errorMsg = this.getCheckDataBeforeApprove(payload);
    if (errorMsg.length) {
      this.alert
        .confirm(
          this.translate.instant(
            'MANAGE-CUSTOMER.INCOMPLETE-INFORMATION',
          ),
          this.translate.instant(
            'MANAGE-CUSTOMER.PLEASE-SPECIFY-THE-LOCATION',
          ),
        )
        .then((res) => {
          if (res?.isConfirmed) {
            this.editProfile();
          }
        });
      return;
    }

    const data = {
      id: payload.id,
    };
    this.spinner.show();
    this.showResult = false;
    this.customerService
      .approveStatusIndividual('approve', data)
      .pipe(
        finalize(() => {
          this.showResult = true;
          this.spinner.hide();
        }),
      )
      .subscribe(
        (res: any) => {
          this.alert.success(
            this.translate.instant('MEMOS.SAVE-CHANGES'),
          );
          this.saveDelete.emit();
          this.getIndividualDetail();
          this.isRepeatEmail = false;
        },
        (er) => {
          this.isRepeatEmail = true;
          this.alert.error(er.error[0]);
        },
      );
  }

  rejectIndividual(event): void {
    const data = {
      id: event,
      reason: this.reason,
    };
    this.spinner.show();
    this.showResult = false;
    this.customerService
      .rejectOrRecoverStatusIndidual('reject', data)
      .pipe(
        finalize(() => {
          this.showResult = true;
          this.spinner.hide();
        }),
      )
      .subscribe(
        (res: any) => {
          this.alert.success(
            this.translate.instant('MEMOS.SAVE-CHANGES'),
          );
          this.saveDelete.emit();
          this.getIndividualDetail();
          this.modalService.dismissAll();
          this.closeModal();
        },
        () => {
          this.alert.error('Can not change status');
        },
      );
  }

  recoverIndividual(event): void {
    const data = {
      id: event,
    };
    this.spinner.show();
    this.showResult = false;
    this.customerService
      .rejectOrRecoverStatusIndidual('recover', data)
      .pipe(
        finalize(() => {
          this.showResult = true;
          this.spinner.hide();
        }),
      )
      .subscribe(
        (res: any) => {
          this.alert.success(
            this.translate.instant('MEMOS.SAVE-CHANGES'),
          );
          this.saveDelete.emit();
          this.getIndividualDetail();
        },
        () => {
          this.alert.error('Can not change status');
        },
      );
  }

  backToDetail(): void {
    this.activateRoute.queryParams.subscribe((res) => {
      this.individual = null;
      this.company = null;
      this.typeCheckDetail = res.type;
      if (res.type === 'individual') {
        this.individualId = res.id;
        if (res.id) {
          this.getIndividualDetail();
        }
      } else {
        this.companyId = res.id;
        if (res.id) {
          this.getCompanyDetail();
        }
      }
    });
    this.showResult = false;
    this.isRepeatEmail = false;
    this.saveDelete.emit();
  }

  formatDateFromLanguage(event) {
    return moment(event)
      .add(this.translate.currentLang === 'th' ? 543 : 0, 'year')
      .format('DD/MM/YYYY');
  }
}
