import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThemeList, ThemeService } from '../../service/theme.service';

@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss'],
})
export class ModalContentComponent {
  @ViewChild('consent', { static: true })
  consent: ElementRef;
  themeList: ThemeList;

  @Output() next = new EventEmitter();
  constructor(
    public modalService: NgbModal,
    private themeService: ThemeService,
  ) {
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  open(config?): void {
    const option = config
      ? config
      : {
          size: 'md',
          backdrop: 'static',
          centered: true,
          keyboard: false,
        };
    this.modalService.open(this.consent, option);
  }

  confirm(): void {
    this.next.emit();
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }
}
