import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SIGNATURE_COLOR_LIST } from '../../../modules/memos/service/upload-memo.constants';
import { base64ToFile } from '../../../core/services/utils.service';
import { CanvasWhiteboardComponent } from 'ng2-canvas-whiteboard';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { AlertService } from '../../../core/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeList } from '../../service/theme.service';
import { OtpModalComponent } from '../otp-modal/otp-modal.component';
import { TextSignatureComponent } from '../../../modules/profile/component/text-signature/text-signature.component';
import { ProfileService } from '../../../modules/profile/shared/profile.service';
import { buildFullName } from '../../utils/common.util';
import { Store } from '@ngxs/store';
import {
  CropperPosition,
  Dimensions,
  ImageCropperComponent,
} from 'ngx-image-cropper';
import { ImageProcessingService } from '../../service/image-processing.service';
import { Person } from 'src/app/modules/profile/shared/profile.models';
import { MEMO_TYPE_CUS_LABEL } from '../../../modules/memos/model/memo';

@Component({
  selector: 'app-select-sign-method-modal',
  templateUrl: './select-sign-method-modal.component.html',
  styleUrls: ['./select-sign-method-modal.component.scss'],
})
export class SelectSignMethodModalComponent {
  /** Enable typing signature text */
  @Input() customTextSignature = false;
  @Input() enableUploadSignature = false;
  @Input() enableUsingStoredSignature = true;
  @Input() enableTextSignature = false;
  @Input() isSaved: boolean;
  @Input() otpEnable: boolean;
  @Input() ddocEnable: boolean;
  @Input() isNeedSign: boolean;
  @Input() isNoneUser = false;
  @Input() themeList: ThemeList;
  @Input() header;
  @Input() numberOfSignature: number = null;
  @Input() countPage: number;
  @Input() requestForm;

  @Input() signatureText: string;
  @Input() otp: string;
  @Output() otpChange = new EventEmitter<string>();

  @Input() signature: File;
  @Output() signatureChange = new EventEmitter<File>();

  @Input() signaturePreview: string;
  @Input() canSignNow = true;
  /** Set the signature thickness on canvas.
   * When set it the responsive thickness is not used
   */
  @Input() signatureThickness: number;
  @Output() confirm = new EventEmitter();
  @Output() closeModal = new EventEmitter();

  @Input() person: Person;
  @Input() memoDetail;
  @Input() isUseStored: boolean;
  @Output() isUseStoredChange = new EventEmitter();

  @ViewChild('canvasWhiteboard', { static: false })
  canvasWhiteboard: CanvasWhiteboardComponent;
  @ViewChild('selectSignMethodModal', { static: false })
  selectSignMethodModal: ElementRef;
  @ViewChild('signSignature', { static: false })
  signSignature: ElementRef;
  @ViewChild('uploadUserSignature', { static: false })
  uploadUserSignature: ElementRef;
  @ViewChild(OtpModalComponent)
  otpModalComponent: OtpModalComponent;
  @ViewChild('imageCropper')
  imageCropperComponent: ImageCropperComponent;

  @Input() isUploadOther = false;

  // Sign now
  signImageBlob: any = null;
  blobOutput: any = '';
  croppedImage: any = '';
  imageChangedEvent: any = '';
  errorMsg: any = '';
  fileInput: any = '';
  signatureColorList = SIGNATURE_COLOR_LIST;
  selectedColor = this.signatureColorList[0];
  showColorPicker = false;
  isSignNow = false;
  isUpload = false;
  uploadOriginalFileName = '';

  selectSignMethodModalRef: NgbModalRef;
  showPopupLine = false;
  cropper: CropperPosition = {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  };
  memoType = [
    'agreement_contract',
    'reservation',
    'contract_car_park',
  ];

  constructor(
    private profileService: ProfileService,
    public modalService: NgbModal,
    private alert: AlertService,
    public translate: TranslateService,
    private store: Store,
    private imageProcessingService: ImageProcessingService,
  ) {}

  /**
   * Calculate signature thickness from the width of canvas.
   * This calculation base on A4 paper (H.297mm x W.210mm).
   * Typically the signature canvas width is about 17.5% of paper (36.75 mm).
   * The common pen ballpoint is 0.5mm.
   * So, a signature thickness is about 1.58% (0.5 / 31.5) of the signature canvas width.
   * @param canvasWidth The width of canvas of signature
   * @returns The thickness of the signature
   */
  calcSignatureThickness(canvasWidth: number): number {
    return canvasWidth * (0.5 / 36.75);
  }

  // TODO - There is the unused parameter
  getSignatureThickness(canvasWidth: number): number {
    return this.signatureThickness || 10;
  }

  openOtpModal(): void {
    this.modalService.dismissAll();
    if (this.requestMemoType) {
      this.otpModalComponent.openSmsRequestForm(
        true,
        this.requestForm,
      );
    } else {
      this.otpModalComponent.openModal(
        true,
        this.memoDetail?.memo_type,
      );
    }
  }

  openSelectSignMethodModal(center = true): void {
    const role = this.store.selectSnapshot<string>(
      (s) => s.auth?.role || this.person?.role,
    );
    if (
      role === 'Customer' ||
      this.requestMemoType ||
      this.isChangeStatusRequestForm
    ) {
      this.onSignNowClick();
    } else {
      this.selectSignMethodModalRef = this.modalService.open(
        this.selectSignMethodModal,
        {
          backdrop: 'static',
          size: 'lg',
          centered: center,
        },
      );
    }
  }

  openSignModal(): void {
    if (this.selectSignMethodModalRef) {
      this.selectSignMethodModalRef.dismiss('Cross Click');
    }
    const signModal = this.modalService.open(this.signSignature, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      centered: true,
    });
    signModal.result.finally(() => (this.isSignNow = false));
  }

  openUploadUserSignature(event): void {
    this.isUpload = true;
    this.signImageBlob = event.target.files[0];
    this.uploadOriginalFileName = this.signImageBlob.name;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {};
    this.openSignModal();
  }

  onClearCanvas(): void {
    this.canvasWhiteboard.clearCanvas();
  }

  onUndoCanvas(): void {
    this.canvasWhiteboard.undo();
  }

  onClickColorPicker(): void {
    this.showColorPicker = !this.showColorPicker;
  }

  onSaveCanvas(): void {
    this.canvasWhiteboard.saveLocal();
  }

  onSelectedColor(color: string): void {
    this.selectedColor = color;
  }

  onSave(event): void {
    this.canvasWhiteboard.generateCanvasBlob((blob) => {
      this.blobOutput = blob;
      this.signImageBlob = blob;
      this.isSignNow = false;
    });
  }

  onSignNowClick(): void {
    this.isUseStoredChange.emit(false);
    this.signatureThickness = 10;
    this.isSignNow = true;
    this.uploadOriginalFileName = 'sign_now.png';
    this.openSignModal();
  }

  onTextSignatureClick(): void {
    if (this.selectSignMethodModalRef) {
      this.selectSignMethodModalRef.dismiss();
    }
    const profile = this.store.selectSnapshot((state) => state.auth);
    const modal = this.modalService.open(TextSignatureComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modal.componentInstance.selectOnly = !this.customTextSignature;
    modal.componentInstance.fullName =
      this.signatureText ||
      buildFullName(profile.first_name, profile.last_name) ||
      '';
    const cancelClick = new EventEmitter();
    cancelClick.subscribe({
      next: () => this.reOpenSelectSignModal(),
    });
    const submitClick = new EventEmitter();
    submitClick.subscribe({
      next: (res) => modal.close(res),
    });
    modal.componentInstance.cancelClick = cancelClick;
    modal.componentInstance.signatureSaveClick = submitClick;
    modal.result
      .then((res) => {
        this.signatureChange.emit(res);
        if (
          this.otpEnable &&
          this.ddocEnable &&
          this.memoDetail?.memo_type !== 'renewal'
        ) {
          this.openOtpModal();
          return;
        } else {
          this.modalService.dismissAll();
          this.otpModalComponent.openRequestFormSms();
        }
        this.submit();
      })
      .catch(() => {});
  }

  imageCropped(output): void {
    this.croppedImage = output.base64;
    this.blobOutput = base64ToFile(
      output.base64,
      this.imageChangedEvent.target?.files[0]?.name,
    );
  }

  imageLoaded(): void {
    // show cropper
  }

  loadImageFailed(): void {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.errorMsg = 'APPROVAL.ERROR-UNABLE-UPLOAD';
  }

  fileChangeEvent(file: any): void {
    this.errorMsg = '';
    this.imageChangedEvent = file;
    this.fileInput = file.target.files[0];
    this.uploadOriginalFileName = this.fileInput.name;
  }

  resetData(): void {
    this.isUpload = false;
    this.uploadOriginalFileName = '';
    this.isSignNow = false;
    this.signImageBlob = null;
    this.blobOutput = '';
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.errorMsg = '';
    this.fileInput = '';
  }

  clearSignature(): void {
    this.alert
      .confirm(this.translate.instant('ALERT.Are you sure'), ' ')
      .then((res) => {
        if (res.value) {
          if (
            this.person?.role === 'Customer' ||
            this.requestMemoType
          ) {
            this.modalService.dismissAll();
            this.resetData();
          } else {
            this.reOpenSelectSignModal();
          }
        }
      });
  }

  changeComplete(event): void {
    this.selectedColor = event.color.hex;
  }

  saveSignature(): void {
    const role = this.store.selectSnapshot<string>(
      (s) => s.auth?.role || this.person?.role,
    );
    this.signature = new File([this.blobOutput], 'online-sign.png', {
      type: this.blobOutput.type,
    });
    this.signatureChange.emit(this.signature);

    if (this.otpEnable && role === 'General') {
      this.openOtpModal();
    } else {
      this.ddocEnable ? this.openOtpModal() : this.submit();
    }
  }

  filePhotoChangeEvent(event: any): void {
    this.isUpload = true;
    this.errorMsg = '';
    this.signImageBlob = event.target.files[0];
    this.uploadOriginalFileName = this.signImageBlob.name;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {};
    this.openSignModal();
    // Clear input file to avoid error when upload same file again
    event.target.value = '';
  }

  submit(useStoredSignature = false): void {
    this.isUseStoredChange.emit(useStoredSignature);
    this.confirm.emit(this.uploadOriginalFileName);
    this.resetData();
  }

  close(): void {
    this.modalService.dismissAll();
    this.closeModal.emit();
    this.resetData();
  }

  reOpenSelectSignModal(): void {
    if (
      this.person?.role === 'Customer' ||
      this.requestMemoType ||
      this.isChangeStatusRequestForm
    ) {
      this.modalService.dismissAll();
      this.resetData();
    } else {
      this.modalService.dismissAll();
      this.resetData();
      this.openSelectSignMethodModal(true);
    }
  }

  verifyOTP(): void {
    if (this.otpModalComponent.invalidOTP) {
      this.alert.error('Input OTP');
      return;
    }

    this.otpChange.emit(this.otp);
    this.submit();
  }

  onEditSizeLine(): void {
    this.showPopupLine = !this.showPopupLine;
  }

  resizeLine(event): void {
    this.getSignatureThickness(this.signatureThickness);
  }

  resetSizeLine(): void {
    this.signatureThickness = 10;
  }

  async onCropperReady(dimensions: Dimensions) {
    if (this.isUpload) {
      return;
    }
    this.cropper =
      await this.imageProcessingService.getSignatureBoundary(
        this.imageCropperComponent.imageFile,
      );
  }

  closeTool(): void {
    this.showColorPicker = false;
    this.showPopupLine = false;
  }

  signSignatureFromStore(): void {
    const role = this.store.selectSnapshot<string>(
      (s) => s.auth?.role || this.person?.role,
    );

    if (this.otpEnable && role === 'General') {
      this.openOtpModal();
    } else {
      this.ddocEnable ? this.openOtpModal() : this.submit();
    }
  }

  get isChangeStatusRequestForm(): boolean {
    return (
      this.memoDetail?.memo_type &&
      this.memoDetail?.memo_type === 'change_status'
    );
  }

  get requestMemoType(): boolean {
    return (
      this.memoDetail?.memo_type &&
      MEMO_TYPE_CUS_LABEL.filter(
        (memo) => memo.value !== 'change_status',
      ).some((item) => item.value === this.memoDetail?.memo_type)
    );
  }
}
