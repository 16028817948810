import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { NewSubjectForm } from './newSubject.actions';

@State({
  name: 'NewSubjectForm',
  defaults: null,
})
@Injectable({
  providedIn: 'root',
})
export class NewSubjectDocumentForm {
  constructor() {}

  @Selector()
  public static newSubject(state) {
    return state;
  }
  @Action(NewSubjectForm)
  saveSubject(
    { setState, getState }: StateContext<any>,
    { value, name }: { value: any; name: string },
  ): void {
    setState({
      ...getState(),
      [name]: value,
    });
  }
}
