import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CachingRequests } from './caching.actions';

export class CachedRequest {
  public request: any;
}

@State<CachedRequest>({
  name: 'cachingRequest',
  defaults: {
    request: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class CachedState {
  constructor() {}

  @Selector()
  public static request(state: CachedRequest) {
    return state;
  }
  @Action(CachingRequests)
  cachingRequests(
    { setState, getState }: StateContext<any>,
    { request },
  ): void {
    setState({
      ...getState(),
      request,
    });
  }
}
