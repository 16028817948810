import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import {
  BehaviorSubject,
  forkJoin,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from '../../../shared/service/api.constant';
import {
  AgreementContract,
  BillingData,
  CommentList,
  ComponentData,
  ComponentSessionConfig,
  DateRange,
  IdCardFile,
  MemoDetail,
  MemoListDetail,
} from '../model/memo.model';
import { UploadMemoPayload } from '../model/template.model';
import { ReservationResults } from '../model/reservationContract.model';
import { catchError } from 'rxjs/operators';
import {
  Memo,
  MemoTypeEvidences,
} from '../../../store/memo/memo.model';
import { replaceUnderscore } from '../../../shared/utils/common.util';

@Injectable({
  providedIn: 'root',
})
export class MemoService {
  headers: HttpHeaders;
  historyText: string;
  loaList: any;
  private setLoa = new Subject();
  data = this.setLoa.asObservable();

  changeTable: any;
  private setTable = new Subject();
  tableData = this.setTable.asObservable();
  requestPo: Observable<any>;

  officeSubject = new Subject();
  loadOfficeSubject = this.officeSubject.asObservable();

  setRefPerson = new Subject();
  loadRefPerson = this.setRefPerson.asObservable();

  setOfficePDF = new Subject();
  loadOfficePDF = this.setOfficePDF.asObservable();

  setPreview = new Subject();
  previewPdf = this.setPreview.asObservable();

  setBuildingQuery$: Subject<number> = new Subject();

  setReferenceStartDateEndDate$: Subject<boolean> = new Subject();
  setStartDate$: Subject<string> = new Subject();
  setEndDate$: Subject<string> = new Subject();
  setCustomer$: Subject<{
    full_name: string;
    person: number;
  }> = new Subject();
  setIncomplete$ = new Subject();

  setLoaderReferenceWaitingInutName$: Subject<boolean> =
    new Subject();

  setRef$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  setDashboardQuery$: BehaviorSubject<RefType> =
    new BehaviorSubject<RefType>({
      location: null,
      property: null,
      building: null,
      floor: null,
      office: null,
      building_code: null,
      office_label: null,
      isDashboard: false,
    });

  reservation: BehaviorSubject<ReservationResults> =
    new BehaviorSubject<ReservationResults>({});

  setClosePreview = new Subject();
  closePreview = this.setClosePreview.asObservable();

  setCalRental = new Subject();
  calculateRental = this.setCalRental.asObservable();

  setAddExtraComponent = new Subject();
  addExtraComponent$ = this.setAddExtraComponent.asObservable();

  private setFormat = new Subject();
  inputDateFormat = this.setFormat.asObservable();

  private setWidthForDate = new Subject();
  widthForDate = this.setWidthForDate.asObservable();

  setNewRef$ = new Subject<boolean>();
  loadNewRef$ = this.setNewRef$.asObservable();

  setNoteDetail$ = new BehaviorSubject<number>(null);
  loadNoteDetail$ = this.setNoteDetail$.asObservable();

  constructor(private http: ApiService) {}
  requestDashboardQuery(val: RefType): void {
    this.setDashboardQuery$.next(val);
  }

  loadDashboardQuest(): Observable<RefType> {
    return this.setDashboardQuery$.asObservable();
  }
  setRefFromDashboard(val: boolean): void {
    this.setRef$.next(val);
  }

  loadReference(): Observable<boolean> {
    return this.setRef$.asObservable();
  }

  setLoaderReference(val: boolean): void {
    this.setLoaderReferenceWaitingInutName$.next(val);
  }
  loadLoaderReference(): Observable<boolean> {
    return this.setLoaderReferenceWaitingInutName$.asObservable();
  }

  setReservation(data: ReservationResults): void {
    this.reservation.next(data);
  }

  getReservation(): Observable<ReservationResults> {
    return this.reservation.asObservable();
  }

  requestStartDateEndDate(date: string, controlName: string): void {
    switch (controlName) {
      case 'start_date':
        this.setStartDate$.next(date);
        break;
      case 'end_date':
        this.setEndDate$.next(date);
        break;
    }
  }
  requestDefaultLoaCustomer(
    val: {
      full_name: string;
      person: number;
    } | null,
  ): void {
    this.setCustomer$.next(val);
  }
  loadDefaultLoaCustomer(): Observable<{
    full_name: string;
    person: number;
  }> {
    return this.setCustomer$.asObservable();
  }
  loadStartDate(): Observable<string> {
    return this.setStartDate$.asObservable();
  }
  loadEndDate(): Observable<string> {
    return this.setEndDate$.asObservable();
  }

  requestBuilding(val: number): void {
    this.setBuildingQuery$.next(val);
  }

  loadBuildingDetail(): Observable<number> {
    return this.setBuildingQuery$.asObservable();
  }

  requestIncomplete(val: number): void {
    this.setIncomplete$.next(val);
  }

  loadIncomplete(): Observable<any> {
    return this.setIncomplete$.asObservable();
  }

  requestReferenceStartDateEndDate(triggerDate: boolean): void {
    this.setReferenceStartDateEndDate$.next(triggerDate);
  }
  loadReferenceStartDateEndDate(): Observable<boolean> {
    return this.setReferenceStartDateEndDate$.asObservable();
  }

  getDepartmentList(data?: { [type: string]: string }) {
    return this.http.get(API_URL.departments, data);
  }
  updateMemoCC(data, mode: 'add' | 'update' = 'update') {
    let url = 'update-cc/';
    if (mode === 'add') {
      url = 'add-cc/';
    }
    return this.http.post(API_URL.memos + url, data);
  }

  previewMemoPDF(data: { [type: string]: string }) {
    return this.http.pdfPost(API_URL.memo_preview, data);
  }

  // My task
  getMyTaskList(params?: { [type: string]: string }) {
    return this.http.get(API_URL.my_task, params);
  }

  getMyTaskBadge(params?: { [type: string]: string }) {
    return this.http.get(API_URL.my_task + 'badge/', params);
  }

  /**
   * Get the unique sessions property from `ComponentData`.
   * If all components is undefined a session then it return empty array.
   */
  getInputComponentSessions(
    inputComponents: ComponentData[],
    ignoreUndefinedGroup = true,
  ): ComponentSessionConfig[] {
    const sessionNames: string[] = [];
    const sessions: ComponentSessionConfig[] = [];
    const retrieveSessionName = (
      session: string | ComponentSessionConfig,
    ) => {
      if (typeof session === 'object') {
        return session.sessionName;
      }
      return session;
    };
    inputComponents.forEach((component) => {
      const sessionName = retrieveSessionName(component.session);
      if (ignoreUndefinedGroup && !sessionName) {
        return;
      }
      const isDuplicated = sessionNames.includes(sessionName);
      if (!isDuplicated && component.componentName != null) {
        sessionNames.push(sessionName);
        sessions.push(this.rewrapSessionConfig(component.session));
      }
    });
    return sessions;
  }

  // RealMemo
  getGeneralMemoList(params?: {
    [type: string]: string;
  }): Observable<MemoListDetail> {
    return this.http.get<MemoListDetail>(
      API_URL.general_memo,
      params,
    );
  }

  getMemoListCSV(data) {
    // url นี้จะไม่ return file อีกแล้ว แต่จะ return celery task id มาแทน
    return this.http.get(API_URL.general_memo + 'excel/', data);
  }

  getMemoTypeCSV(data) {
    return this.http.get(API_URL.general_memo + 'chod-excel/', data);
  }

  getProductListCsv(data) {
    return this.http.get(
      API_URL.general_memo + 'receive-product-list-excel/',
      data,
    );
  }

  getMemoFiles(data) {
    return this.http.getBlob(
      API_URL.general_memo + 'download-multiple-pdf/',
      data,
    );
  }

  getMemoReferenceById(id) {
    return this.http.get(API_URL.memos + id + '/references/');
  }

  updateMemoReferenceById(id, data) {
    return this.http.post(
      API_URL.memos + id + '/update-references/',
      data,
    );
  }

  // Action
  createMemo(data: UploadMemoPayload): Observable<MemoDetail> {
    return this.http.post(API_URL.memos, data);
  }

  updateMemo(id, data): Observable<MemoDetail> {
    return this.http.patch(API_URL.memos + id + '/', data);
  }

  updateAnnouncement(id, data, header?) {
    return this.http.post(
      API_URL.memos + id + '/announcement/',
      data,
      header,
    );
  }

  publishMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/publish/', data);
  }

  extendMemo(id, data) {
    return this.http.post(API_URL.memos + id + '/extend/', data);
  }

  reviseMemo(id) {
    return this.http.post(API_URL.memos + id + '/revise/', {});
  }

  recallMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/recall/', data);
  }

  approveMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/approve/', data);
  }

  rejectMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/reject/', data);
  }

  terminateMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/terminate/', data);
  }

  downloadMemo(id, data?) {
    return this.http.postResponseBlob(
      API_URL.memos + id + '/download-pdf/',
      data,
    );
  }

  getMemoDetail(id: number): Observable<MemoDetail> {
    return this.http.get(API_URL.memos + id + '/');
  }

  getMemoHistory(params?: { [type: string]: string }) {
    return this.http.get(API_URL.memos_history, params);
  }

  getHistoryLogCSV(data) {
    return this.http.getBlob(API_URL.memos_history + 'excel/', data);
  }

  deleteMemo(id) {
    return this.http.delete(API_URL.memos + id + '/');
  }

  // Upload Blob

  uploadBlob(id, data) {
    return this.http.patch(API_URL.upload_memo_blob + id + '/', data);
  }

  uploadMultiBlob(id, data) {
    return this.http.patch(
      API_URL.upload_memo_blob_multi + id + '/',
      data,
    );
  }

  removeBlob(data) {
    return this.http.post(API_URL.remove_memo_blob, data);
  }

  // Comment

  getCommentList(params): Observable<CommentList[]> {
    return this.http.get(API_URL.memo_comment, params);
  }

  createNewComment(data): Observable<CommentList> {
    return this.http.post(API_URL.memo_comment, data);
  }

  updateMemoRead(data) {
    return this.http.post(API_URL.memo_read, data);
  }

  deleteComment(id) {
    return this.http.delete(API_URL.memo_comment + id + '/');
  }

  // Attachment
  getMemoAttachment(params?: { [type: string]: string }) {
    return this.http.get(API_URL.memo_attachment, params);
  }

  removeMemoAttachment(id) {
    return this.http.delete(API_URL.memo_attachment + id + '/');
  }

  updateMemoAttchment(id, data) {
    return this.http.patch(API_URL.memo_attachment + id + '/', data);
  }

  uploadMemoAttachment(data) {
    return this.http.post(API_URL.memo_bulk_attachment, data);
  }

  downloadMemoAttachment(id, data?) {
    return this.http.post(
      API_URL.memo_attachment + id + '/download/',
      data,
    );
  }

  // Verify Duplicated Memo Number
  verifyMemoNumber(params) {
    return this.http.get(API_URL.memo_number_verify, params);
  }

  printFile(url) {
    return this.http.printFile(url);
  }

  getMemoTypes() {
    const params = { type: 'memo_type' };
    return this.http.get(API_URL.dropdown, params);
  }

  getMemoUploadTypes() {
    const params = { type: 'memo_upload_type' };
    return this.http.get(API_URL.dropdown, params);
  }

  setLoadLoaList(department) {
    this.loaList = department;
    this.setLoa.next(this.loaList);
  }

  setInputDate(date) {
    this.setFormat.next(date);
  }

  setWidthDate(event?) {
    this.setWidthForDate.next(event);
  }

  getMemoRevised(params) {
    return this.http.get(API_URL.memo_revised, params);
  }

  // acknowledgement
  getAcknowledge(params) {
    return this.http.get('/api/acknowledges/', params);
  }

  getAcknowledgeCSV(data) {
    return this.http.getBlob('/api/acknowledges/excel/', data);
  }

  // download file
  createDownloadFile(data: any, filename: string): void {
    // for IE10+
    const blob = new Blob([data], { type: data.type });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  rewrapSessionConfig(
    session: string | ComponentSessionConfig,
  ): ComponentSessionConfig {
    if (typeof session === 'string') {
      return { sessionName: session };
    }
    return session;
  }

  updateMemoDetail(id, data) {
    return this.http.patch(API_URL.memo_detail + id + '/', data);
  }

  // trash
  getFinishedMemoList(params?: {
    [type: string]: string;
  }): Observable<MemoListDetail> {
    return this.http.get<MemoListDetail>(
      API_URL.finished_memo,
      params,
    );
  }

  bulkTrashMemo(data) {
    return this.http.post(API_URL.bulk_trash_memo, data);
  }

  bulkUntrashMemo(data) {
    return this.http.post(API_URL.bulk_untrash_memo, data);
  }

  bulkPermanentlyDeleteMemo(data) {
    return this.http.post(API_URL.bulk_permanently_delete_memo, data);
  }

  getFinishedMemoListCSV(data) {
    return this.http.get(API_URL.finished_memo + 'excel/', data);
  }

  exportMemoToGDrive(data) {
    return this.http.post(
      API_URL.finished_memo + 'export-gdrive/',
      data,
    );
  }
  getNdidCredit() {
    return this.http.get('/api/ndid/credit/');
  }

  getThaicomCredit() {
    return this.http.get('/api/thaicom/credit/');
  }

  getIndividualDetail(params) {
    return this.http.get(`/api/add-individual-person/${params}/`);
  }

  getCompanyDetailDetail(params) {
    return this.http.get(`/api/add-company/${params}`);
  }

  requestTermination(id, data) {
    return this.http.post(
      `/api/memos/${id}/request-termination/`,
      data,
    );
  }
  approveTermination(id, data) {
    return this.http.post(
      `/api/memos/${id}/approve-termination/`,
      data,
    );
  }
  rejectTermination(id, data) {
    return this.http.post(
      `/api/memos/${id}/reject-termination/`,
      data,
    );
  }

  getBillingDetail(params): Observable<BillingData> {
    return this.http.get<BillingData>('/api/billing-memo/', params);
  }

  requestAttachmentIDCard(data) {
    return this.http.post(
      `/api/memo-attachments-with-stamp/bulk-create-stamp-copyright/`,
      data,
    );
  }
  uploadIdCard(data, memoType: MemoTypeEvidences) {
    const fixedMemoTypePath = replaceUnderscore(memoType);
    return this.http.post(
      `/api/${fixedMemoTypePath}-evidence/`,
      data,
    );
  }

  destroyIdCard(id: number, memoType: MemoTypeEvidences) {
    const fixedMemoTypePath = replaceUnderscore(memoType);
    return this.http.delete(
      `/api/${fixedMemoTypePath}-evidence/${id}/`,
    );
  }

  destroyedFiles(memo: Memo, loa?: IdCardFile) {
    const hostMemo = memo.memo_type;
    const agreement = memo?.[hostMemo]?.[
      `${hostMemo}_evidences`
    ].filter((evd) => evd.loa_member === loa.id);

    if (agreement[0]?.evidences?.length > 0) {
      const deleteObservables = agreement[0].evidences
        .map((item) => item.id)
        .map((id) =>
          this.destroyIdCard(id, hostMemo as MemoTypeEvidences),
        );

      return new Promise<void>((resolve, reject) => {
        forkJoin(deleteObservables)
          .pipe(
            catchError((error) => {
              console.error('Cannot delete files');
              reject(error);
              return of(null);
            }),
          )
          .subscribe(() => {
            console.log('All files deleted');
            resolve();
          });
      });
    }
  }

  getReceiptNote(params?) {
    return this.http.get('/api/receipt-note/', params);
  }
  uploadExpenseDetailsImage(data, memoType: string) {
    switch (memoType) {
      case 'transfer_document':
        return this.uploadTransferRoomFile(data);
      case 'receive_document':
        return this.uploadReceiveRoomFile(data);
    }
  }

  uploadTransferRoomFile(data, type?) {
    return this.http.post(
      `/api/transfer-room-document-images/`,
      data,
    );
  }

  uploadReceiveRoomFile(data) {
    return this.http.post(`/api/receive-room-document-images/`, data);
  }

  uploadOverviewImagesImage(data, memoType: string) {
    switch (memoType) {
      case 'transfer_document':
        return this.uploadTransferOverViewImages(data);
      case 'receive_document':
        return this.uploadReceiveOverViewImages(data);
    }
  }

  uploadTransferOverViewImages(data) {
    return this.http.post(
      `/api/transfer-room-document-overviews-images/`,
      data,
    );
  }

  uploadReceiveOverViewImages(data) {
    return this.http.post(
      `/api/receive-room-document-overviews-images/`,
      data,
    );
  }

  setPdfPreview(pdf) {
    this.setPreview.next(pdf);
  }

  renderPdf(id: number, data?) {
    return this.http.post(`/api/memos/${id}/recreate-pdf/`, data);
  }

  getResultCalculateDate(data) {
    return this.http.post(`/api/be-utils/calculate-date/`, data);
  }

  getResultCalculateDateRatio(data) {
    return this.http.post(
      `/api/be-utils/calculate-date-ratio/`,
      data,
    );
  }

  getRefMemoDetails(params) {
    return this.http.get(`/api/ref-memo-detail/`, params);
  }

  removeAllMemoAttachment(memo_id) {
    return this.http.post('/api/memo-attachments/remove-all/', {
      memo_id,
    });
  }

  fixSubjectRemoveStatus(subject: string): string {
    let cloneSubject = subject;
    const wordsToRemove = [
      ' (Available)',
      ' (Occupied)',
      ' (Reserved)',
      ' (Renovate)',
      ' (In process)',
      ' (Wait to move out)',
      ' (Contract Expired)',
    ];

    wordsToRemove.forEach((word) => {
      cloneSubject =
        cloneSubject && cloneSubject.replace(word || '', '');
    });
    return cloneSubject;
  }

  createPaymentEvidence(data) {
    return this.http.post(
      API_URL.memo_payment_detail + 'bulk-create/',
      data,
    );
  }

  removeEvidencePayment(id, data) {
    return this.http.post(
      API_URL.memo_payment_detail + id + '/delete-evidence-files/',
      data,
    );
  }
  checkAvailablePdfLang(params: {
    memo_type: string;
  }): Observable<AvailablePdf> {
    return this.http.get<AvailablePdf>(API_URL.lang_memo, params);
  }
}

export interface AvailablePdf {
  pdf_th: boolean;
  pdf_en: boolean;
}

export interface RefType {
  location: number;
  property: number;
  building: number;
  floor: number | number[];
  office: number | number[];
  building_code: any;
  office_label: string;
  isDashboard: boolean;
}
