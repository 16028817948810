<ng-template #signSignature
    let-c="close">
  <app-modal-confirmation
      [title]="(isSignNow ? ('APPROVAL.SIGN-SIGNATURE' | translate) : ('APPROVAL.CONFIRM-SIGNATURE' | translate))"
      (closeModal)="c()"
      (confirmModal)="submit()"
      [hasFooter]="false">
    <ng-template appCustomModalBody>
      <div class="modal-body p-custom-top pb-4">
        <ng-container *ngTemplateOutlet="isSignNow ? signEditor : signSignatureDetail">
        </ng-container>

        <div [ngClass]="{'d-none': !signImageBlob}">
          <div class="justify-content-center"
              style="position: relative">
            <div class="message-box">
              <span class="message-error">{{ errorMsg | translate }}</span>
            </div>
            <div class="row justify-content-end px-2 mt-4"
                [style.--c]="themeList?.header_table_color"
                [style.--bg]="'#fff'">
              <button type="button"
                  class="btn btn-outline"
                  *ngIf="!isUploadOther"
                  [ngStyle]="{'border-color':themeList?.header_table_color, 'color':themeList?.header_table_color}"
                  (click)="isUpload ? reOpenSelectSignModal() : clearSignature()">
                {{ "MEMOS.CANCEL" | translate}}</button>
              <button type="button"
                  class="btn btn-outline"
                  *ngIf="isUploadOther"
                  [ngStyle]="{'border-color':themeList?.header_table_color, 'color':themeList?.header_table_color}"
                  (click)="close()">
                {{ "MEMOS.CANCEL" | translate}}</button>

              <button appSetTheme
                  type="button"
                  class="btn btn-confirm mx-2"
                  (click)="saveSignature()">
                {{ "MEMOS.CONFIRM" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </app-modal-confirmation>
</ng-template>

<ng-template #signEditor>
  <div class="row">
    <div class="col m-t-20 m-b-20">
      <div class="signature-sign-container"
          [style.--bg]="themeList?.header_table_color">
        <div class="d-flex justify-content-between">
          <div class="pt-2">
            {{'MEMOS.PLEASE-SIGN-SIGNATURE' | translate}}
          </div>
          <div class="signature-sign-options"
              (clickOutside)="closeTool()">
            <button class="signature-sign-btn"
                ngbTooltip="{{'MEMOS.EDIT' | translate}}"
                (click)="onEditSizeLine()">
              <svg-icon src="assets/images/icons/pen-fountain.svg"
                  [svgStyle]="{ 'width.px': 20, 'height.px': 20, 'fill': '#ffffff' }"
                  class="pointer"></svg-icon>
            </button>
            <button class="signature-sign-btn"
                ngbTooltip="{{'MEMOS.DELETE' | translate}}"
                (click)="onClearCanvas()">
              <svg-icon src="assets/images/icons/trash-can.svg"
                  [svgStyle]="{ 'width.px': 20, 'height.px': 20, 'fill': '#ffffff' }"
                  class="pointer"></svg-icon>
            </button>
            <button class="signature-sign-btn"
                ngbTooltip="{{'MEMOS.UNDO' | translate}}"
                (click)="onUndoCanvas()">
              <svg-icon src="assets/images/icons/reset.svg"
                  [svgStyle]="{ 'width.px': 20, 'height.px': 20, 'fill': '#ffffff' }"
                  class="pointer"></svg-icon>
            </button>
            <button class="signature-sign-btn"
                ngbTooltip="{{'MEMOS.COLOR' | translate}}"
                (click)="onClickColorPicker()">
              <svg-icon src="assets/images/icons/color-palette.svg"
                  [svgStyle]="{ 'width.px': 20, 'height.px': 20, 'fill': '#ffffff' }"
                  class="pointer"></svg-icon>
            </button>
            <div *ngIf="showPopupLine"
                class="resize-popup-window">
              <div class="d-flex">
                <input type="range"
                    min="1"
                    max="30"
                    [(ngModel)]="signatureThickness"
                    (change)="resizeLine($event)"
                    class="slider"
                    [ngStyle]="{'accent-color': themeList?.header_table_color}">
                <span class="ml-2">{{signatureThickness}}</span>
              </div>
              <div class="text-right">
                <span class="text-red pointer"
                    (click)="resetSizeLine()">reset</span>
              </div>
            </div>
            <div *ngIf="showColorPicker"
                class="color-popup-window">
              <!-- default color list-->
              <div class="color-list">
                <span class="color-item-border"
                    *ngFor="let color of signatureColorList"
                    [style.border-color]="color === selectedColor ? color : 'white'"
                    (click)="onSelectedColor(color)">
                  <span class="color-item"
                      [style.background]="color"></span>
                </span>
              </div>
              <!-- custom color-->
              <div class="color-custom">
                <color-sketch (onChangeComplete)="changeComplete($event)"></color-sketch>
              </div>
            </div>
          </div>
        </div>
        <div #canvasContainer
            class="signature-sign-wrap"
            style="height: 250px;">
          <canvas-whiteboard #canvasWhiteboard
              [clearButtonEnabled]="false"
              [drawButtonEnabled]="false"
              [drawingEnabled]="true"
              [undoButtonEnabled]="false"
              [redoButtonEnabled]="false"
              [colorPickerEnabled]="false"
              [saveDataButtonEnabled]="false"
              [shapeSelectorEnabled]="false"
              [lineWidth]="getSignatureThickness(canvasContainer.offsetWidth)"
              [startingColor]="'transparent'"
              [shouldDownloadDrawing]="false"
              [strokeColor]="selectedColor"
              (onSave)="onSave($event)"
              (mouseover)="closeTool()">
          </canvas-whiteboard>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-end px-2 mt-4"
      [style.--c]="themeList?.header_table_color"
      [style.--bg]="'#fff'">
    <button type="button"
        class="btn btn-outline"
        [ngStyle]="{'border-color':themeList?.header_table_color, 'color':themeList?.header_table_color}"
        (click)="reOpenSelectSignModal()">{{ "MEMOS.CANCEL" | translate }}</button>
    <button appSetTheme
        type="button"
        class="btn btn-confirm mx-2"
        (click)="onSaveCanvas()">{{ "CREATE-USER.CONTINUE" | translate }}</button>
  </div>
</ng-template>

<ng-template #signSignatureDetail>
  <div *ngIf="signImageBlob">
    <h6 class="mt-2 d-inline-block">{{"APPROVAL.SELECT-SIGNATURE-SCOPE"|translate}}</h6>
    <span class="text-red">
      {{"CERTIFICATE.For JPG file the system will automatically remove background" | translate}}
    </span>
    <div class="row">
      <div class="col align-self-center text-center">
        <img class="img-fluid img-thumbnail"
            *ngIf="!signImageBlob"
            src="assets/images/original.png">
        <image-cropper *ngIf="signImageBlob"
            [imageFile]="signImageBlob"
            [imageChangedEvent]="signImageBlob"
            [maintainAspectRatio]="false"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (loadImageFailed)="loadImageFailed()"
            [cropper]="cropper"
            (cropperReady)="onCropperReady($event)"
            format="png"
            outputType="both"
            class="img-thumbnail"
            [style.--bg]="themeList?.header_table_color"
            #imageCropper>
        </image-cropper>
        <input type="file"
            class="d-none"
            (change)="fileChangeEvent($event)"
            #fileSignature>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #selectSignMethodModal
    let-c="close">
  <app-modal-confirmation (closeModal)="c()"
      (confirmModal)="submit()"
      [hasFooter]="false">
    <ng-template appCustomModalHeader>
      <div class="modal-header">
        <div class="flex-grow-1 text-center text-uppercase font-18 font-weight-bold">
          {{"SELECT-SIGN-METHOD.QUESTION" | translate}}
        </div>
        <svg-icon src="assets/images/icons/closeX.svg"
            class="pointer"
            [svgStyle]="{ 'width.px': 22, 'height.px': 22 }"
            (click)="c()"></svg-icon>
      </div>
    </ng-template>
    <ng-template appCustomModalBody>
      <div class="modal-body">
        <p *ngIf="numberOfSignature"
            class="text-center numbers-of-signature">
          {{('SELECT-SIGN-METHOD.You are about to sign $numberOfSignature of $countPage pages.'|translate)
          .replaceAll('$numberOfSignature', numberOfSignature)
          .replaceAll('$countPage', countPage)}}
        </p>
        <div class="row btn-container"
            [style.--c]="themeList?.header_table_color"
            [style.--bg]="'#fff'">
          <div *ngIf="enableTextSignature"
              class="col-12 col-md my-2"
              [ngClass]="[enableTextSignature ? 'col-md-6' : 'col-md']">
            <button class="btn btn-outline d-flex align-items-center justify-content-center mb-3"
                type="button"
                [disabled]="!enableTextSignature"
                (click)="onTextSignatureClick()">
              <span class="far fa-font-case d-inline-block fa-2x px-3"></span>
              <span class="d-inline-block">
                {{"SELECT-SIGN-METHOD.SIGN-BY-TYPING" | translate}}
              </span>
            </button>
          </div>
          <div class="col-12 col-md my-2"
              *ngIf="!isNoneUser"
              [ngClass]="[enableTextSignature ? 'col-md-6' : 'col-md']">
            <button class="btn btn-outline d-flex align-items-center justify-content-center mb-3"
                type="button"
                [disabled]="!enableUsingStoredSignature"
                (click)="otpEnable ? openOtpModal() : submit(true)">
              <svg-icon src="assets/images/icons/sign-saved.svg"
                  class="mr-2"></svg-icon>
              <span class="d-inline-block">{{"SELECT-SIGN-METHOD.STORED" | translate}}</span>
            </button>
          </div>
          <div class="col-12 col-md my-2"
              [ngClass]="[enableTextSignature ? 'col-md-6' : 'col-md']">
            <button class="btn btn-outline d-flex align-items-center justify-content-center mb-3"
                type="button"
                (click)="onSignNowClick()">
              <svg-icon src="assets/images/icons/sign-new.svg"
                  class="mr-2"></svg-icon>
              <span class="d-inline-block">{{"SELECT-SIGN-METHOD.SIGN-NOW" | translate}}</span>
            </button>
          </div>
          <div class="col-12 col-md my-2"
              [ngClass]="[enableTextSignature ? 'col-md-6' : 'col-md']">
            <button class="btn btn-outline d-flex align-items-center justify-content-center mb-3"
                type="button"
                (click)="filePhoto.click()"
                [disabled]="!enableUploadSignature">
              <svg-icon src="assets/images/icons/sign-upload.svg"
                  class="mr-2"></svg-icon>
              <span class="d-inline-block">{{"SELECT-SIGN-METHOD.UPLOAD" | translate}}</span>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </app-modal-confirmation>
</ng-template>

<ng-template #uploadUserSignature
    let-c="close"
    let-d="dismiss"
    class="modal-dialog">
  <div class="modal-header align-items-center">
    <div class="col">
      <h4 class="mb-1">{{"CREATE-USER.EDIT-SIGNATURE" | translate}}</h4>
    </div>
    <div class="col-auto">
      <i class="fas fa-times pointer size-24px"
          data-dismiss="modal"
          (click)="d('Cross click')"></i>
    </div>
  </div>
  <div class="modal-body text-center"
      *ngIf="signaturePreview">
    <img [src]="signaturePreview | secure | async"
        class="img-fluid img-thumbnail blend-hard-light">
    <h6 class="mt-2 font-weight-bold">{{"CREATE-USER.CURRENT-SIGNATURE"|translate}}</h6>
  </div>
  <div class="modal-footer"
      style="position: relative">
    <button type="button"
        class="btn btn-outline-secondary gray"
        (click)="filePhoto.click()">
      {{"CREATE-USER.UPLOAD" |translate}}</button>
    <button type="button"
        class="btn btn-outline-secondary"
        *ngIf="canSignNow"
        (click)="onSignNowClick()">
      {{"SELECT-SIGN-METHOD.SIGN-NOW" |translate}}</button>
  </div>
</ng-template>

<app-otp-modal [header]="header"
    [(otp)]="otp"
    [isSaved]="isSaved"
    (otpChange)="verifyOTP()"
    [requestForm]="requestForm"
    (closeModal)="close()"></app-otp-modal>

<input type="file"
    accept="image/png, image/jpeg"
    class="d-none"
    (change)="filePhotoChangeEvent($event)"
    #filePhoto>
