import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../core/http/api.service';
import { API_URL } from './api.constant';
import { MemoService } from '../../modules/memos/service/memo.service';
import { RESPONSE$ } from '../utils/constants';
import { Observable, forkJoin } from 'rxjs';
import { RequestForm } from '../../modules/request-memo-list/shared/request-memo-list.models';
import { finalize, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  memoNotificationCount = 0;
  waitRequestCount: number | string = 0;
  waitCustomerCount = 0;

  constructor(
    private router: Router,
    private http: ApiService,
    private memoService: MemoService,
  ) {}

  updateNotificationCount() {
    this.memoService.getMyTaskBadge().subscribe((memos: any) => {
      this.memoNotificationCount = memos.count;
    });
  }

  // Feature Notifications

  getAllNotification(params?): RESPONSE$ {
    return this.http.get(API_URL.notification, params);
  }

  markAllAsRead() {
    return this.http.post(API_URL.notification + 'read-all/', null);
  }

  updateIsRead(id) {
    return this.http.post(
      API_URL.notification + id + '/' + 'read' + '/',
      id,
    );
  }

  awaitingRequestFormCount(): void {
    this.http
      .get<{ wait_for_acceptance_count: number | string }>(
        '/api/request-form-memos/',
        { page_size: '1' },
      )
      .pipe(
        map((response) =>
          response.wait_for_acceptance_count >= 99
            ? '99+'
            : response.wait_for_acceptance_count,
        ),
      )
      .subscribe({
        next: (res) => {
          this.waitRequestCount = res;
        },
      });
  }

  awaitingCustomerCount(): void {
    const individual = this.http.get<{ count: number }>(
      '/api/add-individual-person/badge',
    );
    const company = this.http.get<{ count: number }>(
      '/api/add-company/badge',
    );

    forkJoin([individual, company])
      .pipe(map((res) => res[0].count + res[1].count))
      .subscribe((sum) => {
        this.waitCustomerCount = sum;
      });
  }
}

export interface MutedNotification {
  id?: number;
  disabled?: boolean;
  channel: string;
  action: string;
}

export interface NotificationList {
  group: string;
  actions: Actions[];
}

export interface Actions {
  label: string;
  value: string;
}

export interface NotificationChannel {
  name: string;
  label: string;
}
