import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  LangChangeEvent,
  TranslateService,
} from '@ngx-translate/core';
import * as moment from 'moment';
import { AlertService } from 'src/app/core/services/alert.service';
import {
  JOB_LIST,
  NUMBEROFRESIDENT_LIST,
  PREFIX,
  TYPE_COMPANY,
} from 'src/app/modules/customer-page/shared/customer-page';
import {
  DropdownResponse,
  DropdownService,
} from 'src/app/shared/service/dropdown.service';
import { idNumberValidator } from 'src/app/shared/utils/validator';

@Component({
  selector: 'app-customer-add',
  templateUrl: './customer-add.component.html',
  styleUrls: ['./customer-add.component.scss'],
})
export class CustomerAddComponent implements OnInit {
  readonly emailPattern =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  @Input() formGroup: FormGroup;
  @Output() formGroupChange = new EventEmitter<FormGroup>();
  @Input() submitted: boolean;
  @Input() typeItem: string;
  @Output() fileInputChange = new EventEmitter<any>();
  @Output() clearError = new EventEmitter<any>();
  exampleFile: any;
  refreshDropdown = false;
  loadSave = false;
  translated;
  prefixItem = PREFIX;
  prefixCompany = TYPE_COMPANY;
  prefixTypeCard = [
    {
      label: 'บัตรประชาชน',
      label_en: 'ID Card',
      value: 'citizen_id',
    },
    {
      label: 'หนังสือเดินทาง',
      label_en: 'Passport',
      value: 'passport_id',
    },
  ];
  prefixCareer = JOB_LIST;
  statusAddress = false;
  NumberOfOccupantList = NUMBEROFRESIDENT_LIST;
  proviceItem: { label: any; value: any; context?: any }[];
  districtItem: { label: any; value: any; context?: any }[];
  subdistrictitem: { label: any; value: any; context?: any }[];
  locationItem: { label: any; value: any; context?: any }[];
  propertyItem: { label: any; value: any; context?: any }[];
  minDate = {
    year: new Date().getFullYear() - 100,
    month: 1,
    day: 1,
  };
  currentDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  itemBuilding = [];

  postalCodeDropdown = [];
  constructor(
    private dropdownService: DropdownService,
    private translate: TranslateService,
    private builder: FormBuilder,
    private alert: AlertService,
  ) {
    this.translated = translate.currentLang;
    this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.translated = translate.currentLang;
        this.translateDropdown();
      },
    );
  }

  ngOnInit(): void {
    this.formGroup = this.builder.group({
      type_user: [null],
      // individual
      card_type: [null, Validators.required],
      card_no: [
        null,
        [
          Validators.required,
          Validators.minLength(13),
          idNumberValidator,
        ],
      ],
      name_prefix: [null, Validators.required],
      other_prefix: [{ value: null, disabled: true }],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      birth_date: [null, Validators.required],
      job: [null, Validators.required],
      job_other: [null],
      line_id: [null],
      // company
      registration_id: [
        null,
        [Validators.minLength(10), Validators.required],
      ],
      company_type: [null, Validators.required],
      other_company_type: [{ value: null, disabled: true }],
      business_name: [null, Validators.required],
      committees: this.builder.array([
        this.createCommitteeFormGroup(0),
        this.createCommitteeFormGroup(1),
        this.createCommitteeFormGroup(2),
      ]),
      contact_prefix: [null, Validators.required],
      contact_prefix_other: [null],
      contact_first_name: [null, Validators.required],
      contact_last_name: [null, Validators.required],
      contact_email: [null, [Validators.required, Validators.email]],
      contact_phone_number: [
        null,
        [Validators.required, Validators.minLength(10)],
      ],

      house_no: [null, Validators.required],
      village_no: [null],
      road: [null],
      province: [null, Validators.required],
      district: [
        { value: null, disabled: true },
        Validators.required,
      ],
      sub_district: [
        { value: null, disabled: true },
        Validators.required,
      ],
      postal_code: [
        { value: null, disabled: true },
        Validators.required,
      ],
      contact_house_no: [
        { value: null, disabled: false },
        Validators.required,
      ],
      contact_village_no: [{ value: null, disabled: false }],
      contact_road: [{ value: null, disabled: false }],
      contact_province: [
        { value: null, disabled: false },
        Validators.required,
      ],
      contact_district: [
        { value: null, disabled: true },
        Validators.required,
      ],
      contact_sub_district: [
        { value: null, disabled: true },
        Validators.required,
      ],
      contact_postal_code: [
        { value: null, disabled: true },
        Validators.required,
      ],
      email: [
        null,
        [Validators.required, Validators.pattern(this.emailPattern)],
      ],
      secondary_email: [
        null,
        [Validators.pattern(this.emailPattern)],
      ],
      phone_number: [
        null,
        [Validators.required, Validators.minLength(10)],
      ],
      secondary_phone_number: [null, [Validators.minLength(10)]],
      property: [null, Validators.required],
      location: [null, Validators.required],
      building: [null, Validators.required],
      room_reserve: [null, Validators.required],
      duration_contract: [null, Validators.required],
      additional_details: [null],
      number_of_occupants: [null],
    });
    this.reset();
    this.onSelectTypeUser(this.typeItem);
    this.getLocationItem();
    this.formGroup.valueChanges.subscribe((val) => {
      this.formGroupChange.emit(this.formGroup);
    });
    if (this.formGroup.value.type_user === 'company') {
      this.resetValueRequiredValidator();
      this.setValidatorCommittee(0);
    }
  }

  resetValueRequiredValidator(): void {
    this.formGroup
      .get('email')
      .setValidators([Validators.pattern(this.emailPattern)]);
    this.formGroup.get('email').updateValueAndValidity();
    this.formGroup
      .get('phone_number')
      .setValidators([Validators.minLength(10)]);
    this.formGroup.get('phone_number').updateValueAndValidity();
  }

  createCommitteeFormGroup(index: number) {
    const group = this.builder.group({
      id: [],
      prefix: [],
      prefix_other: [],
      first_name: [],
      last_name: [],
      email: [],
      phone_number: [],
    });

    if (index === 0) {
      const prefixControl = group.get('prefix');
      prefixControl.setValidators([Validators.required]);
      prefixControl.updateValueAndValidity();

      const firstnameControl = group.get('first_name');
      firstnameControl.setValidators([Validators.required]);
      firstnameControl.updateValueAndValidity();

      const lastnameControl = group.get('last_name');
      lastnameControl.setValidators([Validators.required]);
      lastnameControl.updateValueAndValidity();

      const emailControl = group.get('email');
      emailControl.setValidators([
        Validators.required,
        Validators.pattern(this.emailPattern),
      ]);
      emailControl.updateValueAndValidity();

      const phoneNumberControl = group.get('phone_number');
      phoneNumberControl.setValidators([
        Validators.required,
        Validators.minLength(10),
      ]);
      phoneNumberControl.updateValueAndValidity();
    }

    return group;
  }

  translateDropdown(): void {
    this.refreshDropdown = true;
    setTimeout(() => {
      this.refreshDropdown = false;
    }, 1);
  }

  onValidateComittees(index: number): void {
    const committees = this.formGroup.get('committees') as FormArray;
    const committee = committees.at(index) as FormGroup;
    if (index === 1 || index === 2) {
      const prefixControl = committee.get('prefix');
      if (prefixControl.value != null) {
        prefixControl.setValidators([Validators.required]);
        prefixControl.updateValueAndValidity();
      } else {
        prefixControl.clearValidators();
        prefixControl.updateValueAndValidity();
      }

      const firstnameControl = committee.get('first_name');
      firstnameControl.setValidators([Validators.required]);
      firstnameControl.updateValueAndValidity();

      const lastnameControl = committee.get('last_name');
      lastnameControl.setValidators([Validators.required]);
      lastnameControl.updateValueAndValidity();

      const emailControl = committee.get('email');
      emailControl.setValidators([
        Validators.required,
        Validators.pattern(this.emailPattern),
      ]);
      emailControl.updateValueAndValidity();

      const phoneNumberControl = committee.get('phone_number');
      phoneNumberControl.setValidators([
        Validators.required,
        Validators.minLength(10),
      ]);
      phoneNumberControl.updateValueAndValidity();
    }
  }
  setValidatorCommittee(index: number): void {
    if (this.formGroup.value.type_user === 'individual') {
      this.onValidateComittees(index);
    } else {
      const committeesArray = this.formGroup.get(
        'committees',
      ) as FormArray;
      const committeeGroup = committeesArray.at(index) as FormGroup;

      committeeGroup.get('prefix').setValidators(Validators.required);
      committeeGroup
        .get('first_name')
        .setValidators(Validators.required);
      committeeGroup
        .get('last_name')
        .setValidators(Validators.required);
      committeeGroup
        .get('email')
        .setValidators([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]);
      committeeGroup
        .get('phone_number')
        .setValidators([
          Validators.required,
          Validators.minLength(10),
        ]);

      if (index > 0 && this.isCommitteesEmpty[index - 1]) {
        committeeGroup.get('prefix').clearValidators();
        committeeGroup.get('first_name').clearValidators();
        committeeGroup.get('last_name').clearValidators();
        committeeGroup.get('email').clearValidators();
        committeeGroup.get('phone_number').clearValidators();
        committeeGroup
          .get('email')
          .setValidators([Validators.pattern(this.emailPattern)]);
        committeeGroup
          .get('phone_number')
          .setValidators([Validators.minLength(10)]);
      }
      committeeGroup.get('prefix').updateValueAndValidity();
      committeeGroup.get('first_name').updateValueAndValidity();
      committeeGroup.get('last_name').updateValueAndValidity();
      committeeGroup.get('email').updateValueAndValidity();
      committeeGroup.get('phone_number').updateValueAndValidity();
    }
  }
  onChange(name_prefix, index) {
    const committeeArray = this.formGroup.get(
      'committees',
    ) as FormArray;
    const committeeGroup = committeeArray.at(index) as FormGroup;

    if (name_prefix && name_prefix.value === 'other') {
      committeeGroup
        .get('prefix_other')
        .setValidators(Validators.required);
    } else {
      committeeGroup.get('prefix_other').clearValidators();
      committeeGroup.get('prefix_other').setValue('');
    }
    committeeGroup.get('prefix_other').updateValueAndValidity();
    this.setValidatorCommittee(index);
  }
  get isCommitteesEmpty(): boolean[] {
    const committees = this.formGroup.get('committees').value;

    const selectedCommittees = committees.slice(1, 3);
    const committeeResults = selectedCommittees.map((committee) => {
      return Object.values(committee).every((val) => {
        return !val;
      });
    });
    return committeeResults;
  }
  reset() {
    this.formGroup.reset();
    this.formGroupChange.emit(this.formGroup);
  }

  onSelectTypeUser(event?): void {
    if (event === 'individual') {
      this.formGroup.patchValue({
        type_user: 'individual',
        card_type: 'citizen_id',
        name_prefix: 'Mr.',
        company_type: '',
      });
      // remove validate
      this.formGroup.get('registration_id').clearValidators();
      this.formGroup.get('company_type').clearValidators();
      this.formGroup.get('other_company_type').clearValidators();
      this.formGroup.get('business_name').clearValidators();
      const committeesArray = this.formGroup.get(
        'committees',
      ) as FormArray;

      committeesArray.controls.forEach(
        (committeeGroup: FormGroup) => {
          committeeGroup.get('prefix').clearValidators();
          committeeGroup.get('prefix_other').clearValidators();
          committeeGroup.get('first_name').clearValidators();
          committeeGroup.get('last_name').clearValidators();
          committeeGroup.get('email').clearValidators();
          committeeGroup
            .get('phone_number')
            .setValidators([Validators.minLength(10)]);
          committeeGroup.get('phone_number').updateValueAndValidity();
        },
      );
      this.formGroup.get('contact_prefix').clearValidators();
      this.formGroup.get('contact_prefix_other').clearValidators();
      this.formGroup.get('contact_first_name').clearValidators();
      this.formGroup.get('contact_last_name').clearValidators();
      this.formGroup.get('contact_email').clearValidators();
      this.formGroup
        .get('contact_phone_number')
        .setValidators([Validators.minLength(10)]);
      this.formGroup
        .get('contact_phone_number')
        .updateValueAndValidity();
    } else {
      this.formGroup.patchValue({
        type_user: 'company',
        card_type: '',
        name_prefix: '',
        company_type: 'limited_company',
      });
      this.formGroup.get('card_type').clearValidators();
      this.formGroup.get('card_no').clearValidators();
      this.formGroup.get('name_prefix').clearValidators();
      this.formGroup.get('other_prefix').clearValidators();
      this.formGroup.get('first_name').clearValidators();
      this.formGroup.get('last_name').clearValidators();
      this.formGroup.get('birth_date').clearValidators();
      this.formGroup.get('job').clearValidators();
      this.formGroup.get('job_other').clearValidators();
      this.formGroup.get('line_id').clearValidators();
      this.formGroup
        .get('contact_phone_number')
        .setValidators([Validators.minLength(10)]);
      this.formGroup
        .get('contact_phone_number')
        .updateValueAndValidity();
    }
    this.getItemProvince();
  }

  changeTypeTitleName(event): void {
    if (event === 'other') {
      this.formGroup.get('other_prefix').enable();
      this.formGroup
        .get('other_prefix')
        .setValidators([Validators.required]);
      this.formGroup
        .get('other_prefix')
        .setErrors({ required: true });
    } else {
      this.formGroup.get('other_prefix').disable();
      this.formGroup.get('other_prefix').setValue(null);
      this.formGroup.get('other_prefix').setErrors(null);
      this.formGroup.get('other_prefix').clearValidators();
    }
    this.formGroup.updateValueAndValidity();
  }

  changeTypeTitleCompany(event): void {
    if (event === 'other') {
      this.formGroup.get('other_company_type').enable();
      this.formGroup
        .get('other_company_type')
        .setValidators([Validators.required]);
      this.formGroup
        .get('other_company_type')
        .setErrors({ required: true });
    } else {
      this.formGroup.get('other_company_type').disable();
      this.formGroup.get('other_company_type').setValue(null);
      this.formGroup.get('other_company_type').setErrors(null);
      this.formGroup.get('other_company_type').clearValidators();
    }
    this.formGroup.updateValueAndValidity();
  }

  changeCardTypeForDisabledForm(event): void {
    if (event === 'passport_id') {
      // reset form
      this.formGroup.get('house_no').reset();
      this.formGroup.get('village_no').reset();
      this.formGroup.get('road').reset();
      this.formGroup.get('province').reset();
      this.formGroup.get('district').reset();
      this.formGroup.get('sub_district').reset();
      this.formGroup.get('postal_code').reset();
      // disabled form
      this.formGroup.get('house_no').disable();
      this.formGroup.get('village_no').disable();
      this.formGroup.get('road').disable();
      this.formGroup.get('province').disable();
      this.formGroup.get('district').disable();
      this.formGroup.get('sub_district').disable();
      this.formGroup.get('postal_code').disable();
      // reset validate for passport
      this.formGroup.get('card_no').clearValidators();
      this.formGroup
        .get('card_no')
        .setValidators([Validators.required]);
      this.formGroup.updateValueAndValidity();
    } else {
      // enable form
      this.formGroup.get('house_no').enable();
      this.formGroup.get('village_no').enable();
      this.formGroup.get('road').enable();
      this.formGroup.get('province').enable();
      this.formGroup.get('district').enable();
      this.formGroup.get('sub_district').enable();
      this.formGroup.get('postal_code').enable();
      // reset validate for card id
      this.formGroup.get('card_no').clearValidators();
      this.formGroup
        .get('card_no')
        .setValidators([
          Validators.required,
          Validators.minLength(17),
          idNumberValidator,
        ]);
      this.formGroup.updateValueAndValidity();
    }
  }

  getItemProvince(): void {
    const params = {
      type: 'province',
    };
    this.dropdownService.getDropdown(params).subscribe(
      (dropdown: DropdownResponse) => {
        this.proviceItem = dropdown.province;
      },
      (error) => {
        this.alert.error(error.status);
      },
    );
  }

  clearValueAddress(): void {
    this.formGroup.get('district').disable();
    this.formGroup.get('sub_district').disable();
    this.formGroup.get('postal_code').disable();
    this.formGroup.get('province').reset();
    this.formGroup.get('district').reset();
    this.formGroup.get('sub_district').reset();
    this.formGroup.get('postal_code').reset();
  }

  clearValueCopyAddress(): void {
    this.formGroup.get('contact_district').disable();
    this.formGroup.get('contact_sub_district').disable();
    this.formGroup.get('contact_postal_code').disable();
    this.formGroup.get('contact_province').reset();
    this.formGroup.get('contact_district').reset();
    this.formGroup.get('contact_sub_district').reset();
    this.formGroup.get('contact_postal_code').reset();
  }

  getItemDistrict(event): void {
    this.districtItem = [];
    if (!event?.value) {
      return;
    }
    const params = {
      type: 'district',
      province: event.value,
    };
    this.dropdownService.getDropdown(params).subscribe(
      (dropdown: DropdownResponse) => {
        this.districtItem = dropdown.district;
        if (this.districtItem.length) {
          this.formGroup.get('district').enable();
          // reset form
          this.formGroup.get('district').reset();
          this.formGroup.get('sub_district').reset();
          this.formGroup.get('postal_code').reset();
          // disable form
          this.formGroup.get('sub_district').disable();
          this.formGroup.get('postal_code').disable();
        }
      },
      (error) => {
        this.alert.error(error.status);
      },
    );
  }

  getItemDistrictContact(event): void {
    this.districtItem = [];
    if (!event?.value) {
      return;
    }
    const params = {
      type: 'district',
      province: event.value,
    };
    this.dropdownService.getDropdown(params).subscribe(
      (dropdown: DropdownResponse) => {
        this.districtItem = dropdown.district;
        if (this.districtItem.length) {
          this.formGroup.get('contact_district').enable();
          // reset form
          this.formGroup.get('contact_district').reset();
          this.formGroup.get('contact_sub_district').reset();
          this.formGroup.get('contact_postal_code').reset();
          // disable form
          this.formGroup.get('contact_sub_district').disable();
          this.formGroup.get('contact_postal_code').disable();
        }
      },
      (error) => {
        this.alert.error(error.status);
      },
    );
  }

  getItemSubDistrict(event): void {
    this.subdistrictitem = [];
    const params = {
      type: 'subdistrict',
      district: event.value,
    };
    this.dropdownService.getDropdown(params).subscribe(
      (dropdown: DropdownResponse) => {
        this.subdistrictitem = dropdown.subdistrict;
        if (this.subdistrictitem.length) {
          this.formGroup.get('sub_district').enable();
          // reset form
          this.formGroup.get('postal_code').reset();
          this.formGroup.get('sub_district').reset();
          // disable form
          this.formGroup.get('postal_code').disable();
        }
      },
      (error) => {
        this.alert.error(error.status);
      },
    );
  }

  getItemSubDistrictContact(event): void {
    this.subdistrictitem = [];
    const params = {
      type: 'subdistrict',
      district: event.value,
    };
    this.dropdownService.getDropdown(params).subscribe(
      (dropdown: DropdownResponse) => {
        this.subdistrictitem = dropdown.subdistrict;
        if (this.subdistrictitem.length > 0) {
          this.formGroup.get('contact_sub_district').enable();
          // reset form
          this.formGroup.get('contact_postal_code').reset();
          this.formGroup.get('contact_sub_district').reset();
          // disable form
          this.formGroup.get('contact_postal_code').disable();
        }
      },
      (error) => {
        this.alert.error(error.status);
      },
    );
  }

  getPostralCode(event): void {
    const params = {
      type: 'postal_code',
      sub_district: event.value,
    };
    this.dropdownService.getDropdown(params).subscribe(
      (dropdown: DropdownResponse) => {
        this.postalCodeDropdown = dropdown.postal_code;
        if (this.postalCodeDropdown.length > 0) {
          this.formGroup.get('postal_code').reset();
          if (this.statusAddress) {
            this.formGroup.controls.contact_postal_code.setValue(
              this.formGroup.get('postal_code').value || null,
            );
          }
        }
      },
      (error) => {
        this.alert.error(error.status);
      },
    );
  }

  getPostralCodeContact(event): void {
    if (event) {
      this.formGroup.get('contact_postal_code').enable();
      const params = {
        type: 'postal_code',
        sub_district: event.value,
      };
      this.dropdownService.getDropdown(params).subscribe(
        (dropdown: DropdownResponse) => {
          this.postalCodeDropdown = dropdown.postal_code;
          this.formGroup.get('contact_postal_code').reset();
        },
        (error) => {
          this.alert.error(error.status);
        },
      );
    } else {
      this.formGroup.get('contact_postal_code').setValue(null);
    }
  }

  get contactPostalCode() {
    return this.formGroup.get('contact_postal_code').value;
  }

  onSelectCopyAddress(event: any): void {
    if (this.statusAddress) {
      this.formGroup.patchValue({
        contact_house_no: this.formGroup.get('house_no').value,
        contact_village_no: this.formGroup.get('village_no').value,
        contact_road: this.formGroup.get('road').value,
        contact_province: this.formGroup.get('province').value,
        contact_district: this.formGroup.get('district').value,
        contact_sub_district:
          this.formGroup.get('sub_district').value,
        contact_postal_code: this.formGroup.get('postal_code').value,
      });
      this.formGroup.get('contact_house_no').disable();
      this.formGroup.get('contact_village_no').disable();
      this.formGroup.get('contact_road').disable();
      this.formGroup.get('contact_province').disable();
      this.formGroup.get('contact_district').disable();
      this.formGroup.get('contact_sub_district').disable();
      this.formGroup.get('contact_postal_code').disable();
    } else {
      this.formGroup.patchValue({
        contact_house_no: null,
        contact_village_no: null,
        contact_road: null,
        contact_province: null,
        contact_district: null,
        contact_sub_district: null,
        contact_postal_code: null,
      });
      this.formGroup.get('contact_house_no').enable();
      this.formGroup.get('contact_village_no').enable();
      this.formGroup.get('contact_road').enable();
      this.formGroup.get('contact_province').enable();
      this.formGroup.get('contact_district').disable();
      this.formGroup.get('contact_sub_district').disable();
      this.formGroup.get('contact_postal_code').disable();
    }
    this.formGroup.updateValueAndValidity();
  }

  getLocationItem(): void {
    const params = {
      type: 'location',
    };
    this.dropdownService.getDropdown(params).subscribe(
      (dropdown: DropdownResponse) => {
        this.locationItem = dropdown.location;
      },
      (error) => {
        this.alert.error(error.status);
      },
    );
  }

  getItemProperty(event): void {
    if (!event?.value) {
      this.propertyItem = [];
      this.itemBuilding = [];
      this.formGroup.get('property').setValue(null);
      this.formGroup.get('building').setValue(null);
      return;
    }

    const params = {
      type: 'property',
      location: event.value,
    };
    this.dropdownService.getDropdown(params).subscribe(
      (dropdown: DropdownResponse) => {
        this.itemBuilding = [];
        this.formGroup.get('property').setValue(null);
        this.formGroup.get('building').setValue(null);
        this.propertyItem = dropdown.property;
      },
      (error) => {
        this.alert.error(error.status);
      },
    );
  }

  getBuildingItem(event): void {
    if (!event?.value) {
      this.itemBuilding = [];
      this.formGroup.get('building').setValue(null);
      return;
    }

    const params = {
      type: 'building',
      property: event.value,
    };
    this.dropdownService.getDropdown(params).subscribe(
      (dropdown: DropdownResponse) => {
        this.itemBuilding = dropdown.building;
        this.formGroup.get('building').setValue(null);
      },
      (error) => {
        this.alert.error(error.status);
      },
    );
  }

  onSelectCareer(event): void {
    if (event.value === 'other') {
      this.formGroup
        .get('job_other')
        .setValidators([Validators.required]);
    } else {
      this.formGroup.get('job_other').setValue(null);
      this.formGroup.get('job_other').setErrors(null);
      this.formGroup.get('job_other').clearValidators();
    }
    this.formGroup.updateValueAndValidity();
  }

  onChangeHouseNo(event): void {
    if (this.statusAddress) {
      this.formGroup.controls.contact_house_no.setValue(
        this.formGroup.get('house_no').value || null,
      );
    }
  }

  onChangeVillageNo(event): void {
    if (this.statusAddress) {
      this.formGroup.controls.contact_village_no.setValue(
        this.formGroup.get('village_no').value || null,
      );
    }
  }

  onChangeRoad(event): void {
    if (this.statusAddress) {
      this.formGroup.controls.contact_road.setValue(
        this.formGroup.get('road').value || null,
      );
    }
  }

  onChangeProvince(event): void {
    if (this.statusAddress) {
      this.formGroup.controls.contact_province.setValue(
        this.formGroup.get('province').value || null,
      );
      this.formGroup.controls.contact_postal_code.setValue(null);
    }
  }

  onChangeDistrict(event): void {
    if (this.statusAddress) {
      this.formGroup.controls.contact_district.setValue(
        this.formGroup.get('district').value || null,
      );
      this.formGroup.controls.contact_postal_code.setValue(null);
    }
  }

  onChangeSubDistrict(event): void {
    if (this.statusAddress) {
      this.formGroup.controls.contact_sub_district.setValue(
        this.formGroup.get('sub_district').value || null,
      );
      this.formGroup.controls.contact_postal_code.setValue(null);
    }
    this.formGroup.get('postal_code').enable();
  }

  onChangePostalCode(event): void {
    if (this.statusAddress) {
      this.formGroup.controls.contact_postal_code.setValue(
        this.formGroup.get('postal_code').value || null,
      );
    }
  }

  formatDateFromLanguage(event) {
    return moment(event)
      .add(this.translate.currentLang === 'th' ? 543 : 0, 'year')
      .format('DD/MM/YYYY');
  }

  get currentLang(): string {
    return this.translate.currentLang;
  }
}
