import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Directive({
  selector: '[appMultipleMaxSelection]',
})
export class MultipleMaxSelectionDirective {
  constructor(private ngSelect: NgSelectComponent) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(model: any[]) {
    if (model && model.length > 1) {
      const latestSelection = model[model.length - 1];
      this.ngSelect.writeValue([latestSelection]);
    }
  }
}
