import {
  finalize,
  first,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  HideLoaderAction,
  ShowLoaderAction,
} from '../loader/load.actioins';
import { cloneDeep, forEach, get } from 'lodash';
import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { AlertService } from 'src/app/core/services/alert.service';
import { MemoService } from 'src/app/modules/memos/service/memo.service';
import {
  AddAttachFile,
  AddCustomLoaLevel,
  CloneLoa,
  CreateMemo,
  ErrorNotification,
  PaymentEvidence,
  PreviewMemo,
  RemoveCustomLoaLevel,
  ResetCustomLoa,
  ResetState,
  SaveItemMemo,
  SaveMemo,
  UpdateCustomLoaLevel,
  UpdateMemo,
  UploadFile,
  UploadFileContract,
  UploadTransferReceiveFile,
} from './memo.actions';
import { LoaLevel, Memo, MemoCreationData } from './memo.model';
import { MemoDetail } from 'src/app/modules/memos/model/memo.model';
import { forkJoin, Observable, EMPTY } from 'rxjs';
import {
  environment,
  featureFlag,
} from '../../../environments/environment';
import { DDocSigningStatus } from '../../modules/loa/shared/loa.model';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ApiService } from 'src/app/core/http/api.service';
import {
  CONTRACT_CAN_REF_MEMOS,
  MEMOS_HAS_PAYMENT_DETAILS,
  TRANSFER_RECEIVE_MEMOS,
} from '../../modules/memos/model/memo';

@State<MemoCreationData<any>>({
  name: 'memoCreationData',
  defaults: {
    memoCreationData: null,
    isPreview: false,
    memoPreviewData: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class MemoCreationState {
  constructor(
    private memoService: MemoService,
    private alert: AlertService,
    private translate: TranslateService,
    private zone: NgZone,
    private store: Store,
    private route: Router,
    private spinner: SpinnerService,
    private http: ApiService,
  ) {}

  @Selector()
  static memoCreationData(
    state: MemoCreationData<MemoDetail>,
  ): MemoCreationData<MemoDetail> {
    return state;
  }

  @Action(AddCustomLoaLevel)
  addLoaLevel(
    ctx: StateContext<Memo>,
    payload: { name: string },
  ): Observable<void> {
    const state = ctx.getState();
    const pushLevelFn = (
      underState: Partial<Memo>,
    ): Partial<Memo> => {
      const presentLevel = underState.custom_loa_group.levels;
      const defaultApprover = {
        person: null,
        is_delegate: false,
        person_name: null,
      };
      const defaultLevel = {
        level: presentLevel.length,
        name: payload.name,
        members: [defaultApprover],
        signature_required: true,
        min_approve_count: 1,
        count: 1,
        user_type: 'user',
      };
      if (featureFlag.ddoc) {
        /** Default ddoc_enable of the new level follow last level before */
        const loaLevels = presentLevel;
        const lastLevelDdocEnable =
          loaLevels[(loaLevels.length || 1) - 1]?.ddoc_enable;
        Object.assign(defaultLevel, {
          ddoc_enable: lastLevelDdocEnable || false,
        });
        /** fill default signing status */
        const defaultSigningStatus: DDocSigningStatus = {
          ddoc_use_26: lastLevelDdocEnable || false,
          ddoc_use_28: false,
          ddoc_certified_mode: false,
          ddoc_locked_mode: false,
        };
        Object.assign(defaultApprover, defaultSigningStatus);
      }
      underState.custom_loa_group.levels.push(defaultLevel);
      return { custom_loa_group: underState.custom_loa_group };
    };
    if (state.custom_loa_group == null) {
      return ctx.dispatch(new ResetCustomLoa()).pipe(
        tap(() => {
          ctx.patchState(pushLevelFn(ctx.getState()));
        }),
      );
    }
    ctx.patchState(pushLevelFn(state));
  }

  /**  Create & Update Memo */
  @Action(SaveMemo)
  save(
    { setState }: StateContext<MemoCreationData<MemoDetail>>,
    { payload },
  ): void {
    setState({
      ...payload,
    });
  }

  @Action(SaveItemMemo)
  saveItem(
    { setState, getState }: StateContext<MemoCreationData<any>>,
    { value, name }: { value: any; name: string },
  ): void {
    const state = getState();

    setState({
      ...state,
      [name]: value,
    });
  }

  @Action(AddAttachFile)
  addFile(
    {
      setState,
      getState,
    }: StateContext<MemoCreationData<MemoDetail>>,
    { file }: { file: File },
  ): void {
    const state = getState();
    const stateClone = cloneDeep(state);
    stateClone.attachments = [...stateClone.attachments, file];
    setState({
      ...stateClone,
    });
  }

  @Action(RemoveCustomLoaLevel)
  removeCustomLoaLevel(
    ctx: StateContext<Memo>,
    action: RemoveCustomLoaLevel,
  ): void {
    const state = ctx.getState();
    const customLoa = state.custom_loa_group;
    if (state.custom_loa_group.levels?.length < 1) {
      return;
    }
    if (action.index == null) {
      customLoa.levels.pop();
    } else {
      customLoa.levels.splice(action.index, 1);
    }
    ctx.patchState({
      custom_loa_group: customLoa,
    });
  }

  @Action(ResetCustomLoa)
  resetCustomLoa(ctx: StateContext<Memo>): void {
    ctx.patchState({
      custom_loa_group: { levels: [] },
    });
  }

  @Action(ResetState)
  resetState({
    setState,
  }: StateContext<MemoCreationData<any>>): void {
    setState({
      memoCreationData: null,
      isPreview: false,
      memoPreviewData: null,
    });
  }

  @Action(CreateMemo)
  createMemo(
    { getState }: StateContext<MemoCreationData<any>>,
    { payload }: { payload: any },
  ): Observable<MemoDetail> {
    this.store.dispatch(new ShowLoaderAction());
    return this.memoService.createMemo(payload).pipe(
      // ดูว่าทำไม่ถึงใช้ switchMap ที่ action 'UpdateMemo'
      // look at notes in action 'UpdateMemo' on why I use switchMap.
      switchMap((createMemoRes) => {
        const actions$ = [
          this.store
            .dispatch(new UploadFile(createMemoRes.id))
            .pipe(map(() => createMemoRes)),
        ];

        if (MEMOS_HAS_PAYMENT_DETAILS.includes(payload.memo_type)) {
          actions$.push(
            this.store
              .dispatch(new PaymentEvidence(createMemoRes.id))
              .pipe(map(() => createMemoRes)),
          );
        }
        return forkJoin(actions$).pipe(map(() => createMemoRes));
      }),
      tap((res) => {
        // this.store.dispatch(new UploadFileContract(res.id, true));
        this.store.dispatch(new UploadFile(res.id));
        // re-attach attachment since updateMemo api don't send attachment back
        res.attachments = payload.attachments;
        this.store.dispatch(new SaveMemo(res));
      }),
      finalize(() => {
        this.store.dispatch(new HideLoaderAction());
        if (!TRANSFER_RECEIVE_MEMOS.includes(payload.memo_type)) {
          this.spinner.hide();
        }
      }),
      take(1),
    );
  }

  @Action(PreviewMemo)
  previewMemo(
    { getState }: StateContext<MemoCreationData<any>>,
    { payload }: { payload: any },
  ): Observable<MemoDetail> {
    this.store.dispatch(new ShowLoaderAction());
    return this.memoService.createMemo(payload).pipe(
      // ดูว่าทำไม่ถึงใช้ switchMap ที่ action 'UpdateMemo'
      // look at notes in action 'UpdateMemo' on why I use switchMap.
      switchMap((updateMemos) => {
        const actions$ = [
          this.store
            .dispatch(new UploadFile(updateMemos.id))
            .pipe(map(() => updateMemos)),
        ];

        if (MEMOS_HAS_PAYMENT_DETAILS.includes(payload.memo_type)) {
          actions$.push(
            this.store
              .dispatch(new PaymentEvidence(updateMemos.id))
              .pipe(map(() => updateMemos)),
          );
        }
        return forkJoin(actions$).pipe(map(() => updateMemos));
      }),
      tap((res) => {
        const memoType =
          payload.memo_type === 'transfer_document'
            ? 'transfer_document'
            : 'receive_document';
        if (memoType === payload.memo_type) {
          this.store.dispatch(
            new SaveItemMemo(
              res[memoType].expense_details,
              'expense_details',
            ),
          );
          this.store.dispatch(
            new SaveItemMemo(
              res[memoType].overview_images,
              'overview_images',
            ),
          );
        } else {
          this.store.dispatch(
            new SaveItemMemo(res.signed_document, 'signed_document'),
          );
        }
        this.store.dispatch(new SaveItemMemo(res.id, 'id'));
      }),
      finalize(() => {
        if (!TRANSFER_RECEIVE_MEMOS.includes(payload.memo_type)) {
          this.store.dispatch(new HideLoaderAction());
        }
      }),
      take(1),
    );
  }

  @Action(UpdateCustomLoaLevel)
  updateCustomLoaLevel(
    ctx: StateContext<Memo>,
    action: {
      index: number;
      updatedLoaLevel: LoaLevel;
    },
  ): void {
    const state = ctx.getState();
    const customLoa = state.custom_loa_group;
    customLoa.levels[action.index] = action.updatedLoaLevel;
    ctx.patchState({
      custom_loa_group: customLoa,
    });
  }

  @Action(UpdateMemo)
  updateMemo(
    { getState }: StateContext<MemoCreationData<MemoDetail>>,
    { id, payload }: { id: number; payload: any },
  ): Observable<MemoDetail> {
    this.store.dispatch(new ShowLoaderAction());
    return this.memoService.updateMemo(id, payload).pipe(
      // TH: Note 1: ที่เราทำ switchMap ตรงนี้เพราะว่า ต้องการดูว่า action 'UploadFile' มันสำเร็จหรือเปล่า
      //       ถ้ามันไม่สำเร็จ ก็คนที่ subscribe function นี้ก็จะได้ event error กลับไปด้วย
      //     Note 2: ที่มีการ 'pipe(map(() => updateMemoRes))' ตอนท้ายก็เพราะต้องการให้ tap ด้านล่างมัน process
      //       response ของ updateMemo ไม่ใช่ response ของ UploadFile
      //
      // EN: Note 1: I use switchMap here because I want to wait for 'UploadFile' action finished.
      //       if it is failed, the error event to raised to everyone that subscribe to this function.
      //     Note 2: I use 'pipe(map(() => updateMemoRes))' in the end because I want 'tap' below to process
      //       response of 'updateMemo', not response of 'UploadFile'
      switchMap((updateMemos) => {
        const actions$ = [
          this.store
            .dispatch(new UploadFile(updateMemos.id))
            .pipe(map(() => updateMemos)),
        ];

        if (MEMOS_HAS_PAYMENT_DETAILS.includes(payload.memo_type)) {
          actions$.push(
            this.store
              .dispatch(new PaymentEvidence(updateMemos.id))
              .pipe(map(() => updateMemos)),
          );
        }
        return forkJoin(actions$).pipe(map(() => updateMemos));
      }),
      tap((res) => {
        const memoType =
          payload.memo_type === 'transfer_document'
            ? 'transfer_document'
            : 'receive_document';

        const checkDocumentMemo = TRANSFER_RECEIVE_MEMOS.includes(
          payload.memo_type,
        );

        if (checkDocumentMemo) {
          this.store.dispatch(
            new SaveItemMemo(
              res[memoType].expense_details,
              'expense_details',
            ),
          );
          this.store.dispatch(
            new SaveItemMemo(
              res[memoType].overview_images,
              'overview_images',
            ),
          );
        } else {
          this.store.dispatch(
            new SaveItemMemo(res.signed_document, 'signed_document'),
          );
        }
        this.store.dispatch(new SaveItemMemo(res.id, 'id'));
      }),
      finalize(() => {
        this.store.dispatch(new HideLoaderAction());
        if (!TRANSFER_RECEIVE_MEMOS.includes(payload.memo_type)) {
          this.spinner.hide();
        }
      }),
      take(1),
    );
  }

  @Action(UploadFile)
  uploadPMemo(
    { getState }: StateContext<MemoCreationData<any>>,
    { id }: { id: number },
  ): Observable<any> {
    const payload = cloneDeep(getState());
    if (!payload.attachments || payload.attachments.length === 0) {
      return EMPTY;
    }
    const newAttachments = payload.attachments.filter(
      (file) => file.id === undefined,
    );
    if (newAttachments == null || newAttachments.length === 0) {
      return EMPTY;
    }
    const at = new FormData();
    at.append('memo', id + '');
    forEach(newAttachments, (file) => {
      const name = get(file, 'name', 'relativePath');
      file.fileEntry
        ? at.append(name, file.fileEntry)
        : at.append(name, file);
    });
    return this.memoService.uploadMemoAttachment(at).pipe(
      tap({
        next: (res: []) => {
          const attachments = payload.attachments.filter(
            (file) => file.id,
          );
          if (res.length) {
            attachments.push(...res);
          }
          this.store.dispatch(
            new SaveItemMemo(attachments, 'attachments'),
          );
        },
      }),
    );
  }

  @Action(PaymentEvidence)
  createPaymentEvidence(
    { getState }: StateContext<MemoCreationData<any>>,
    { id }: { id: number },
  ): Observable<any> {
    const payload = cloneDeep(getState());
    const newEvidences = payload?.payment_evidences.filter(
      (file) => file.id === undefined || !file.id,
    );
    const at = new FormData();
    at.append('memo', id + '');
    at.append('payment_method', payload.payment_detail);
    at.append('payment_method_other', payload.payment_method_other);
    if (newEvidences != null || newEvidences.length > 0) {
      newEvidences.forEach((file, index) => {
        const newIndex = payload.payment_evidences.length + index + 1;
        at.append(`evidence_${newIndex}`, file);
      });
    }
    return this.memoService.createPaymentEvidence(at).pipe(
      tap({
        next: (res: []) => {
          const attachments = payload.payment_evidences.filter(
            (file) => file.id,
          );
          if (res.length) {
            attachments.push(...res);
          }
          this.store.dispatch(
            new SaveItemMemo(attachments, 'payment_evidences'),
          );
        },
      }),
    );
  }
  // for upload PDF ID card
  @Action(UploadFileContract)
  async UploadContractPDFMemo(
    { getState }: StateContext<MemoCreationData<any>>,
    { id }: { id: number },
  ) {
    const payload = cloneDeep(getState());
    if (
      !payload.attachmentCard ||
      payload.attachmentCard.length === 0
    ) {
      return;
    }
    const newAttachments = payload.attachmentCard.filter(
      (file) => file.id === undefined,
    );
    const at = new FormData();
    at.append('memo', id + '');
    forEach(newAttachments, (file) => {
      const name = get(file, 'name', 'relativePath');
      file.fileEntry
        ? at.append(name, file.fileEntry)
        : at.append(name, file);
    });
    this.memoService.requestAttachmentIDCard(at).subscribe(
      (res: []) => {
        const attachmentCard = payload.attachmentCard.filter(
          (file) => file.id,
        );
        if (res.length) {
          attachmentCard.push(...res);
        }
        this.store.dispatch(
          new SaveItemMemo(attachmentCard, 'attachmentCard'),
        );
      },
      (error) => {
        this.alert.error(
          this.translate.instant('MASTER-DATA.ERROR-UPLOAD-ID-CARD'),
        );
      },
    );
  }

  /**  Check Error Message */
  @Action(ErrorNotification)
  errorNotification(
    { getState }: StateContext<MemoCreationData<any>>,
    { error },
  ): void {
    if (error.error && error.error.en) {
      this.alert.error(
        error.error[this.translate.currentLang].join('\n'),
      );
    } else if (error.error && error.error.non_field_errors) {
      const non_field_errors: string[] = error.error.non_field_errors;
      this.alert.error(non_field_errors.join('\n'));
    } else if (error.error) {
      const errorInfos: string[] = [];
      for (const value of Object.values(error.error || {})) {
        if (typeof value === 'string') {
          errorInfos.push(value);
        } else if (Array.isArray(value)) {
          errorInfos.push(
            ...value.filter((i) => typeof i === 'string'),
          );
        }
      }
      this.alert.error(errorInfos.join('\n'));
    } else {
      this.alert.error();
    }
  }

  @Action(CloneLoa)
  cloneLoa(
    ctx: StateContext<any>,
    { normalLoa, payload }: { normalLoa: boolean; payload?: any },
  ) {
    let state: { isNormalLoa: any; cloneLoa?: boolean } = {
      isNormalLoa: normalLoa,
    };
    const cloneState = ctx.getState();
    if (cloneState.cloneLoa !== undefined) {
      state = {
        isNormalLoa: normalLoa,
        cloneLoa: cloneState.cloneLoa,
      };
    }

    if (payload !== undefined) {
      state.cloneLoa = payload;
    }
    ctx.patchState({
      ...state,
    });
  }

  @Action(UploadTransferReceiveFile)
  async UploadTransferReceiveFile(
    { getState }: StateContext<MemoCreationData<any>>,
    {
      id,
      memoData,
      preview,
      memoCreation,
    }: { id: number; memoData: any; preview: any; memoCreation: any },
  ) {
    const payload = cloneDeep(memoData);
    const createCreation = (controlName: string) => {
      return cloneDeep(
        memoCreation[payload.memo_type]?.[controlName]
          ? memoCreation[payload.memo_type]?.[controlName]
          : memoCreation?.[controlName],
      );
    };

    const fetchNewImages = async (
      image: ImagesDetails,
    ): Promise<Blob> => {
      const url = image.file.includes(environment.baseUrl)
        ? image.file
        : environment.baseUrl + image.file;
      const blob = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            'currentUser',
          )}`,
        },
      }).then((r) => r.blob());
      return new File([blob], image.file_name as string, {
        type: 'image/jpeg',
      });
    };

    const creationExpenseDetails = createCreation('expense_details');
    const creationOverviewImages = createCreation('overview_images');

    // is upload expense details
    const expenseDetailsFile = new FormData();
    const expenseDetails: ExpenseDetailsImage[] =
      payload.expense_details;

    for (const itemsImages of expenseDetails) {
      const itemsIndex: number =
        payload.expense_details.indexOf(itemsImages);

      for (const image of itemsImages.images) {
        const imageIndex: number = itemsImages.images.indexOf(image);
        const transferImportImage =
          image?.file && image?.file.includes(environment.baseUrl);
        // if image from reference id must have null
        if (
          !image.id ||
          (image.id && !image.file && !image.images) ||
          (image.id && !transferImportImage)
        ) {
          image.id = creationExpenseDetails[itemsIndex].images[
            imageIndex
          ].id as string;
          const _imageExpenseDetail = image.file
            ? await fetchNewImages(image)
            : image.images;
          expenseDetailsFile.append(
            image.id,
            _imageExpenseDetail || '',
          );
        }
      }
    }

    // is upload overview images
    const overviewImagesFiles = new FormData();
    const overviewImage: ImagesDetails[] = payload.overview_images;

    for (const img of overviewImage) {
      // if image from reference id must have nulls
      if (
        payload.memo_type === 'transfer_document' ||
        (payload.memo_type === 'receive_document' && !img.is_transfer)
      ) {
        const index: number = creationOverviewImages.findIndex(
          (oimg) => oimg.sequence === img.sequence,
        );
        if (!img.id || (img.id && !img.file && !img.images)) {
          img.id = creationOverviewImages[index].id as string;
          const _imageOverviewImage = img.file
            ? await fetchNewImages(img)
            : img.images;
          overviewImagesFiles.append(
            img.id,
            _imageOverviewImage || '',
          );
        }
      }
    }

    const expenseDetailsService: Observable<unknown> =
      this.memoService.uploadExpenseDetailsImage(
        expenseDetailsFile,
        payload.memo_type,
      );
    const overviewImageService: Observable<unknown> =
      this.memoService.uploadOverviewImagesImage(
        overviewImagesFiles,
        payload.memo_type,
      );

    forkJoin([expenseDetailsService, overviewImageService])
      .pipe(
        tap(() => this.memoService.setPdfPreview(preview ?? false)),
      )
      .subscribe((res: any) => {
        if (res) {
          if (res[1]?.length > 0) {
            const map = memoData.overview_images.map((item) => {
              const newItems = res[1].find(
                (newImg) => newImg.sequence === item.sequence,
              );
              if (newItems) {
                item = {
                  ...newItems,
                  is_transfer:
                    payload.memo_type === 'transfer_document',
                };
              }
              return item;
            });
            this.store.dispatch(
              new SaveItemMemo(map, 'overview_images'),
            );
          } else {
            this.store.dispatch(
              new SaveItemMemo(
                memoData.overview_images,
                'overview_images',
              ),
            );
          }
          if (res[0]?.length > 0) {
            const map = memoData.expense_details.map((item) => {
              item?.images.map((img) => {
                const newImg = res[0].find(
                  (imgRes) => imgRes.id === img.id,
                );
                if (newImg) {
                  img = newImg;
                }
                return img;
              });
              return item;
            });
            this.store.dispatch(
              new SaveItemMemo(map, 'expense_details'),
            );
          } else {
            this.store.dispatch(
              new SaveItemMemo(
                memoData.expense_details,
                'expense_details',
              ),
            );
          }
        } else {
          ['expense_details', 'overview_images'].forEach((key) => {
            this.store.dispatch(new SaveItemMemo(memoData[key], key));
          });
        }
      });
  }
}

export interface ExpenseDetailsImage {
  [key: string]: any;
  images?: ImagesDetails[];
}

export interface ImagesDetails {
  id: string | number | null;
  file_name: string;
  sequence: string | number;
  file?: string | null;
  path: string;
  images?: File | string;
  is_transfer?: boolean;
}
