<!-- OTP by google auth -->
<ng-template #otpGoogleAuthen
    let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{"APPROVAL.ENTER-OTP" | translate}}
    </h4>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pt-4">
    <div class="text-center mt-3 mb-3 custom-box">
      <div class="mb-2">
        {{"PROFILE.Enter the code generated by your authenticator application"|translate}}
      </div>
      <div class="mb-5 f-12 text-red">
        {{"PROFILE.OTP cannot be reused. Please wait 30 seconds for a new code"|translate}}
      </div>
      <ng-otp-input #ngOtpInput
          (onInputChange)="onOtpChange($event)"
          [config]="config"
          (keydown.enter)="otpChange.emit(otp)"></ng-otp-input>
    </div>

    <div class="form-group text-center mt-5 mb-0">
      <button [disabled]="invalidOTP"
          (click)="otpChange.emit(otp)"
          [ladda]="isSaved"
          class="btn"
          type="button"
          appSetTheme>{{"APPROVAL.CONFIRM-APPROVE" | translate}}</button>
    </div>
  </div>
</ng-template>

<!-- OTP by email -->
<ng-template #otpEmail
    let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-uppercase">
      {{"APPROVAL.ENTER-OTP" | translate}}
    </h4>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center mb-5">
      <div>
        {{"Email-OTP.EMAIL-OTP-SENT" | translate}} {{ hiddenEmail }} (Ref: {{refDisPlay}})
      </div>
      <div class="countdown"
          [class.text-danger]="timeLeft === 0">
        {{"SMS-OTP.Your code will expire in" | translate}}
        {{ timeLeft | minuteSeconds }} {{"SMS-OTP.minutes" | translate}}
      </div>
    </div>
    <div class="text-center mt-3 mb-3 custom-box">
      <ng-otp-input #ngOtpInput
          (onInputChange)="onOtpChange($event)"
          [config]="config"
          (keydown.enter)="otpChange.emit(otp)"></ng-otp-input>
    </div>
    <div class="text-center mt-4">
      <div>{{"SMS-OTP.If you haven't received the code" | translate}}
        <span>
          <a class="text-blue border-bottom pointer"
              (click)="resend()">{{"SMS-OTP.resend the OTP" | translate}}</a>
        </span>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <button type="button"
        [style.--c]="themeList?.header_table_color"
        class="btn btn-outline btn-w-80"
        (click)="c('Close click')">{{"CREATE-USER.CANCEL"|translate}}
    </button>
    <button [disabled]="invalidOTP"
        (click)="otpChange.emit(otp)"
        [ladda]="isSaved"
        class="btn btn-w-80"
        type="button"
        appSetTheme>{{"APPROVAL.CONFIRM" | translate}}</button>
  </div>

</ng-template>

<!-- OTP by phone number -->
<ng-template #otpPhoneNumber
    let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{"APPROVAL.ENTER-OTP" | translate}}
    </h4>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center mb-5">
      <div>
        {{"CREATE-USER.OTP-SENT" | translate}} {{ hiddenNumber }} (Ref: {{refDisPlay}})
      </div>
      <div class="countdown"
          [class.text-danger]="timeLeft === 0">
        {{"SMS-OTP.Your code will expire in" | translate}}
        {{ timeLeft | minuteSeconds }} {{"SMS-OTP.minutes" | translate}}
      </div>
    </div>
    <div class="text-center mt-3 mb-3 custom-box">
      <ng-otp-input #ngOtpInput
          (onInputChange)="onOtpChange($event)"
          [config]="config"
          (keydown.enter)="otpChange.emit(otp)"></ng-otp-input>
    </div>
    <div class="text-center mt-4">
      <div>{{"SMS-OTP.If you haven't received the code" | translate}}
        <span>
          <a class="text-blue border-bottom pointer"
              (click)="resend()">{{"SMS-OTP.resend the OTP" | translate}}</a>
        </span>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <button type="button"
        [style.--c]="themeList?.header_table_color"
        class="btn btn-outline btn-w-80"
        (click)="c('Close click')">{{"CREATE-USER.CANCEL"|translate}}
    </button>
    <button [disabled]="invalidOTP"
        (click)="otpChange.emit(otp)"
        [ladda]="isSaved || isLoadingOTP"
        class="btn btn-w-80"
        type="button"
        appSetTheme>{{"APPROVAL.CONFIRM" | translate}}</button>
  </div>

</ng-template>


<!--otp request form-->

<ng-template #otpRequestForm
             let-c="close"
             let-d="dismiss">
  <div class="modal-header otp-line">
    <h5 class="modal-title">
      {{'LANDING.OTP-VERIFY' | translate}}
    </h5>
    <button type="button"
            class="close"
            aria-label="Close"
            (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <div class="form-group">
      <div>
        <div class="text-center mb-5">
          <div>
            {{"CREATE-USER.OTP-SENT" | translate}} {{ hiddenNumberRequestForm }} (Ref: {{refDisPlay}})
          </div>
          <div class="countdown" [class.text-danger]="timeLeft === 0">
            {{ timeLeft | minuteSeconds}} {{"CREATE-USER.MIN-LEFT" | translate}}
          </div>
        </div>
        <div class="mt-1 custom-input">
          <input class="f-20 col-8 "
                  inputmode="numeric"
                  [disabled]="!ref"
                  (keypress)="numberOnly($event)"
                  (keyup.enter)="otpChange.emit(this.otp)"
                  maxlength="6"
                  [(ngModel)]="otp">
        </div>
      </div>
    </div>
    <div class="text-center mt-3">
      <button class="btn btn-success"
              [ladda]="isLoadingOTP"
              [disabled]="!ref"
              (click)="otpChange.emit(this.otp)">{{'LANDING.OTP-CONFIRM' | translate}}</button>
      <div class="mt-4">
        <a class="text-muted border-bottom pointer"
           (click)="openSmsRequestForm(false)">{{'LANDING.OTP-RESEND' | translate}}</a>
      </div>
    </div>
  </div>
</ng-template>
