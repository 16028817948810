import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerEditComponent } from './components/customer-edit/customer-edit.component';
import { CustomerUserComponent } from './components/customer-user/customer-user.component';

const routes: Routes = [
  { path: '', component: CustomerUserComponent },
  { path: 'edit', component: CustomerEditComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerUserRoutingModule {}
