import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'company',
})
export class CompanyPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    if (value) {
      return (
        {
          13: value.replace(
            /(\d{1})(\d{2})(\d{1})(\d{3})(\d{5})/,
            '$1-$2-$3-$4-$5-',
          ),
        }[value.length] || value
      );
    }
  }
}
