<ng-container *ngIf="typeLoading === 'standard'">
<div *ngIf="loading">
  <div class="loader"></div>
</div>
</ng-container>

<ng-container *ngIf="typeLoading === 'blade'">
  <!--https://uiverse.io/mrhyddenn/black-bullfrog-16-->
  <div class="spinner-center center" *ngIf="loading">
    <div class="spinner-center-blade"></div>
    <div class="spinner-center-blade"></div>
    <div class="spinner-center-blade"></div>
    <div class="spinner-center-blade"></div>
    <div class="spinner-center-blade"></div>
    <div class="spinner-center-blade"></div>
    <div class="spinner-center-blade"></div>
    <div class="spinner-center-blade"></div>
    <div class="spinner-center-blade"></div>
    <div class="spinner-center-blade"></div>
    <div class="spinner-center-blade"></div>
    <div class="spinner-center-blade"></div>
  </div>
</ng-container>
