import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDefaultZero]',
})
export class DefaultZeroDirective implements OnInit, OnDestroy {
  @Input() typeValue: 'date' | null;

  private subscription: Subscription;

  constructor(private ngControl: NgControl) {}

  ngOnInit() {
    const control = this.ngControl.control;
    this.subscription = control.valueChanges.subscribe((v) => {
      if (v === null || v === undefined || v === '') {
        control.setValue(0);
      }
      if (this.typeValue === 'date' && v > 99) {
        control.setValue(99);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
