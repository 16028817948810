<div class="row">
    <div class="col-6 mt-3">
        <h3 class="ml-3">
            <fa-icon [icon]="chevronLeft" [size]="2" class="pointer mt-2 mr-3" (click)="onClickBack()"></fa-icon>
            <span> {{"LOA.EDIT" |translate}}</span>
        </h3>
    </div>
    <div class="col-6 mt-3 d-flex justify-content-end">
        <button class="btn" type="button" appSetTheme (click)="openConfirmModal(editDepartment)">{{"CREATE-USER.SAVE"
            |translate}}
        </button>
    </div>
</div>

<div class="container mb-4 custom-box">
    <div class="row justify-content-center">
        <div class="col-12 profile-image text-center">
            <div class="position-relative">
                <div class="profile">
                    <div class="profile-pic hover-profile"
                        [style.backgroundImage]="'url(' + (photoPreview || 'assets/images/image_preview_default.png') + ')'">
                        <div class="custom-position">
                            &nbsp;
                        </div>
                        <div ngbDropdown (click)="openPhoto(cropImgUser)" class="d-inline-block btn-edit">
                            <div class="custom-btn pointer" id="dropdownBasic1" ngbDropdownToggle>
                                <svg-icon src="assets/svg/icon-profile/camera.svg"
                                    [svgStyle]="{ 'fill': '#808080', 'height.px': 20, 'width.px': 20}">
                                </svg-icon>
                                <br><span class="pl-3 text-white">
                                    {{"CREATE-USER.Edit-profile-picture"|translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <form *ngIf="formGroup" [formGroup]="formGroup">
                <div class="row">

                    <div class="col-12 p-0" *ngIf="formGroup.value.type_user">
                        <!-- card type -->
                        <div class="form-group col-12 col-md-12" *ngIf="formGroup.value.type_user === 'individual'">
                            <label class="col-form-label mb-2">
                                {{"MANAGE-CUSTOMER.TYPE-CARD" | translate}}
                                <span class="text-red">*</span>
                            </label>
                            <div class="col-12 pl-0 row">
                                <div class="col-auto form-check form-check-inline pointer"
                                    *ngFor="let item of prefixTypeCard">
                                    <input class="form-check-input" id="item.value" type="radio" [value]="item.value"
                                        [attr.disabled]="true" formControlName="card_type"
                                        (ngModelChange)="changeCardTypeForDisabledForm($event)"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.card_type.invalid}">
                                    <label class="form-check-label" id="item.value" for="item.value">
                                        {{translated === 'th' ? item.label : item.label_en}}
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="submitted && formGroup.controls.card_type.invalid" class="text-red pt-1 pl-1">
                                <span *ngIf="formGroup.controls.card_type.errors.required">
                                    {{"MANAGE-CUSTOMER.VALID-CARD-OR-PASSPORT"|translate}}</span>
                            </div>
                        </div>

                        <!-- id card and passport input-->
                        <div class="form-group col-12 col-md-12" *ngIf="formGroup.value.type_user === 'individual'">
                            <label class="col-form-label" *ngIf="formGroup.value.card_type === 'citizen_id'">
                                {{"MANAGE-CUSTOMER.ID-CARD" | translate}}
                                <span class="text-red">*</span>
                            </label>
                            <label class="col-form-label" *ngIf="formGroup.value.card_type === 'passport_id'">
                                {{"MANAGE-CUSTOMER.PASSPORT" | translate}}
                                <span class="text-red">*</span>
                            </label>
                            <div class="col-12 pl-0">
                                <input *ngIf="formGroup.value.card_type === 'citizen_id'" type="text"
                                    inputmode="numeric" class="form-control" [imask]="{mask: '0-0000-00000-00-0'}"
                                    placeholder="{{'MANAGE-CUSTOMER.ID-CARD'| translate}}" formControlName="card_no"
                                    (keydown.enter)="$event.preventDefault();"
                                    [ngClass]="{ 'is-invalid': submitted && formGroup.controls.card_no.invalid}">
                                <input *ngIf="formGroup.value.card_type === 'passport_id'" type="text"
                                    class="form-control" placeholder="{{'MANAGE-CUSTOMER.PASSPORT'| translate}}"
                                    formControlName="card_no" maxlength="20"
                                    [ngClass]="{ 'is-invalid': submitted && formGroup.controls.card_no.invalid}"
                                    (keydown.enter)="$event.preventDefault()">
                            </div>
                            <div *ngIf="submitted && formGroup.controls.card_no.invalid" class="text-red pt-1 pl-1">
                                <span *ngIf="formGroup.controls.card_no.errors.required">
                                    {{"MANAGE-CUSTOMER.VALID-ID-CARD-OR-PASSPORT"|translate}}</span>
                            </div>
                            <span class="text-red pt-1 pl-1"
                                *ngIf="formGroup.controls.card_no.invalid && formGroup.controls.card_no.value">
                                {{"MANAGE-CUSTOMER.VALID-ID-CARD-MIN"|translate}}
                            </span>
                        </div>

                        <!-- id registation -->
                        <div class="form-group col-12 col-md-12" *ngIf="formGroup.value.type_user === 'company'">
                            <label class="col-form-label">
                                {{"MANAGE-CUSTOMER.TAX-ID" | translate}}
                                <span class="text-red">*</span>
                            </label>
                            <div class="col-12 pl-0">
                                <input type="text" class="form-control"
                                    placeholder="{{'MANAGE-CUSTOMER.TAX-ID' | translate}}"
                                    formControlName="registration_id" [imask]="{mask: '0-00-0-000-00000-0'}"
                                    [unmask]="true"
                                    [ngClass]="{ 'is-invalid': submitted && formGroup.controls.registration_id.invalid}">
                            </div>
                            <div *ngIf="submitted && formGroup.controls.registration_id.invalid"
                                class="text-red pt-1 pl-1">
                                <span *ngIf="formGroup.controls.registration_id.errors.required">
                                    {{"MANAGE-CUSTOMER.VALID-REGISTRATION-ID"|translate}}</span>
                            </div>
                            <span class="text-red pt-1 pl-1"
                                *ngIf="formGroup.controls.registration_id?.hasError('minlength')">
                                {{"MANAGE-CUSTOMER.VALID-REGISTRATION-ID-MIN"|translate}}
                            </span>
                        </div>

                        <!-- title name -->
                        <div class="form-group col-12 col-md-12" *ngIf="formGroup.value.type_user === 'individual'">
                            <label class="col-form-label">
                                {{"MANAGE-CUSTOMER.NAME-PREFIX" | translate}}
                                <span class="text-red">*</span>
                            </label>
                            <div class="col-12 px-0 row">
                                <div class="col-12 mt-lg-1 mt-3 form-check form-check-inline pointer"
                                    *ngFor="let item of prefixItem"
                                    [ngClass]="item.value === 'other' ? 'col-lg pr-0' : 'col-lg-auto'">
                                    <input class="form-check-input" id="item.value" type="radio" [value]="item.value"
                                        formControlName="name_prefix" (change)="changeTypeTitleName(item.value)"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.name_prefix.invalid}">
                                    <label class="form-check-label pointer" [for]="item.value">
                                        {{translated === 'th' ? item.label : item.label_en}}
                                    </label>
                                    <div class="ml-2 px-0 w-100" *ngIf="item.value==='other'">
                                        <input type="text" class="form-control ml-2"
                                            placeholder="{{'MANAGE-CUSTOMER.PLEASE-SPECIFY' | translate}}"
                                            formControlName="other_prefix"
                                            [ngClass]="{ 'is-invalid': submitted && formGroup.controls.other_prefix.invalid}">
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="submitted && formGroup.controls.name_prefix.invalid" class="text-red pt-1 pl-1">
                                <span *ngIf="formGroup.controls.name_prefix.errors.required">
                                    {{"MANAGE-CUSTOMER.VALID-TITLE-NAME"|translate}}</span>
                            </div>
                            <div *ngIf="submitted && formGroup.controls.other_prefix.invalid"
                                class="text-red pt-1 pl-1">
                                <span *ngIf="formGroup.controls.other_prefix.errors.required">
                                    {{"MANAGE-CUSTOMER.VALID-TITLE-NAME"|translate}}</span>
                            </div>
                        </div>

                        <!-- title company -->
                        <div class="form-group col-12 col-md-12" *ngIf="formGroup.value.type_user === 'company'">
                            <label class="col-form-label">
                                {{"MANAGE-CUSTOMER.LEGAL-ENTITY-TYPE" | translate}}
                                <span class="text-red">*</span>
                            </label>
                            <div class="col-12 px-0 row">
                                <div class="col-12 form-check form-check-inline pointer"
                                    [ngClass]="item.value === 'other' ? 'mt-2 pr-0' : 'mt-3'"
                                    *ngFor="let item of prefixCompany">
                                    <input class="form-check-input" [id]="item.value" type="radio" [value]="item.value"
                                        name="company_type" formControlName="company_type"
                                        (change)="changeTypeTitleCompany(item.value)"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.company_type.invalid}">
                                    <label class="form-check-label pointer" [for]="item.value">
                                        {{translated === 'th' ? item.label : item.label_en}}
                                    </label>
                                    <div class="ml-2 px-0 w-100" *ngIf="item.value==='other'">
                                        <input type="text" class="form-control w-100"
                                            placeholder="{{'MANAGE-CUSTOMER.PLEASE-SPECIFY' | translate}}"
                                            formControlName="other_company_type"
                                            [ngClass]="{ 'is-invalid': submitted && formGroup.controls.other_company_type.invalid}">
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="submitted && formGroup.controls.company_type.invalid"
                                class="text-red pt-1 pl-1">
                                <span *ngIf="formGroup.controls.company_type.errors.required">
                                    {{"MANAGE-CUSTOMER.VALID-LEGAL-ENTITY-TYPE"|translate}}</span>
                            </div>
                            <div *ngIf="submitted && formGroup.controls.other_company_type.invalid"
                                class="text-red pt-1 pl-1">
                                <span *ngIf="formGroup.controls.other_company_type.errors.required">
                                    {{"MANAGE-CUSTOMER.VALID-LEGAL-ENTITY-TYPE"|translate}}</span>
                            </div>
                        </div>

                        <div class="row px-3">
                            <!-- name -->
                            <div class="form-group col-12 col-md-6" *ngIf="formGroup.value.type_user === 'individual'">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.NAME" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control"
                                        placeholder="{{'MANAGE-CUSTOMER.NAME' | translate}}"
                                        formControlName="first_name"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.first_name.invalid}">
                                </div>
                                <div *ngIf="submitted && formGroup.controls.first_name.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.first_name.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-NAME"|translate}}</span>
                                </div>
                            </div>

                            <!-- last name -->
                            <div class="form-group col-12 col-md-6" *ngIf="formGroup.value.type_user === 'individual'">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.LAST-NAME" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control"
                                        placeholder="{{'MANAGE-CUSTOMER.LAST-NAME' | translate}}"
                                        formControlName="last_name"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.last_name.invalid}">
                                </div>
                                <div *ngIf="submitted && formGroup.controls.last_name.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.last_name.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-LAST-NAME"|translate}}</span>
                                </div>
                            </div>

                            <!-- birth day -->
                            <div class="form-group col-12 col-md-6" *ngIf="formGroup.value.type_user === 'individual'">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.BIRTHDAY" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <input *ngIf="!refreshDropdown" class="form-control input-lg date-picker"
                                        name="invf" formControlName="birth_date" ngbDatepicker [minDate]="minDate"
                                        [maxDate]="currentDate" placeholder="{{'MANAGE-CUSTOMER.BIRTHDAY' | translate}}"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.birth_date.invalid}"
                                        (click)="invf.toggle()" #invf="ngbDatepicker" autocomplete="off"
                                        (ngModelChange)="formatDateFromLanguage($event)">
                                    <span class="span-calendar">
                                        <i _ngcontent-kkw-c5="" class="fa fa-calendar i-calendar" aria-hidden="true"
                                            ngbDatepicker (click)="invf.toggle()"></i>
                                    </span>
                                </div>
                                <div *ngIf="submitted && formGroup.controls.birth_date.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.birth_date.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-BIRTHDAY"|translate}}</span>
                                </div>
                            </div>

                            <!-- job -->
                            <div class="form-group col-12 col-md-6" *ngIf="formGroup.value.type_user === 'individual'">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.CAREER" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <ng-select [items]="prefixCareer"
                                        placeholder="{{'MANAGE-CUSTOMER.CAREER' | translate}}" class="custom"
                                        [bindLabel]="translated === 'en'? 'label_en':'label'" bindValue="value"
                                        [clearable]="false" formControlName="job" (change)="onSelectCareer($event)"
                                        [notFoundText]="'SELECT.No items found'|translate"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.job.invalid}">
                                    </ng-select>
                                </div>
                                <div *ngIf="submitted && formGroup.controls.job.invalid" class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.job.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-JOB"|translate}}</span>
                                </div>
                            </div>

                            <!-- job other -->
                            <div class="form-group col-12 col-md-6" *ngIf="formGroup.value.type_user === 'individual'
                                    && formGroup.value.job === 'other'">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.PLEASE-SPECIFY" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control"
                                        placeholder="{{'MANAGE-CUSTOMER.PLEASE-SPECIFY' | translate}}"
                                        formControlName="job_other">
                                </div>
                                <div *ngIf="submitted && formGroup.controls.job_other.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.job_other.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-JOB"|translate}}</span>
                                </div>
                            </div>

                            <!-- line id -->
                            <div class="form-group col-12 col-md-6" *ngIf="formGroup.value.type_user === 'individual'">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.LINE-ID" | translate}}
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control"
                                        placeholder="{{'MANAGE-CUSTOMER.LINE-ID' | translate}}"
                                        formControlName="line_id"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.line_id.invalid}">
                                </div>
                                <div *ngIf="submitted && formGroup.controls.line_id.invalid" class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.line_id.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-LINE-ID"|translate}}</span>
                                </div>
                            </div>

                            <!-- name company -->
                            <div class="form-group col-12 col-md-12" *ngIf="formGroup.value.type_user === 'company'">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.NAME" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control"
                                        placeholder="{{'MANAGE-CUSTOMER.NAME' | translate}}"
                                        formControlName="business_name"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.business_name.invalid}">
                                </div>
                                <div *ngIf="submitted && formGroup.controls.business_name.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.business_name.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-NAME-COMPANY"|translate}}</span>
                                </div>
                            </div>
                        </div>

                        <!-- comittee company-->
                        <div formArrayName="committees" *ngIf="formGroup.value.type_user === 'company'">
                            <div *ngFor="let committee of formGroup.get('committees').controls;
                                let i = index">
                                <div [formGroupName]="i">
                                    <!-- text  comittees-->
                                    <div class="form-group col-12 col-md-12" appSetTheme type="headerTable">
                                        <label class="col-form-label text-white">
                                          {{"MANAGE-CUSTOMER.COMMITTEE" | translate}} ({{"MANAGE-CUSTOMER.DIRECTOR-NO" | translate}}{{i+1}})
                                        </label>
                                    </div>

                                    <div class="row px-3" >
                                      <div class="form-group col-12 col-md-2">
                                          <label class="col-form-label">
                                              {{"MANAGE-CUSTOMER.NAME-PREFIX" | translate}}
                                              <span class="text-red"
                                                  *ngIf="i === 0">*</span>
                                          </label>

                                            <div class="col-12 pl-0 ">
                                              <ng-select class="custom"
                                                         formControlName="prefix"
                                                         placeholder="{{'MANAGE-CUSTOMER.PREFIX-SELECT' | translate}}"
                                                         (change)="onChange($event,i)"
                                                         [ngClass]="{ 'is-invalid': submitted &&
                                                         formGroup.get('committees.'+i+'.prefix').invalid}">
                                                <ng-option *ngFor="let item of prefixItem" [value]="item.value">
                                                  {{translated === 'th' ? item.label : item.label_en}}
                                                </ng-option>
                                              </ng-select>
                                            </div>
                                          </div>

                                              <div class="form-group col-12 col-md-2" style="padding-right: 0px"
                                                  *ngIf="formGroup.get('committees.'+i+'.prefix').value === 'other'">
                                                  <label class="col-form-label">
                                                    {{"MANAGE-CUSTOMER.OTHER" | translate}}
                                                </label>
                                                  <div class="col-12 p-0">
                                                      <input type="text"
                                                              class="form-control"
                                                              (change)="setValidatorCommittee(i)"
                                                              placeholder="{{'MANAGE-CUSTOMER.PLEASE-SPECIFY' | translate}}"
                                                              formControlName="prefix_other">
                                                  </div>
                                              </div>
                                        <!-- commitee name -->
                                        <div class="form-group col">
                                            <label class="col-form-label">
                                                {{"MANAGE-CUSTOMER.NAME" | translate}}
                                                <span class="text-red" *ngIf="i === 0">*</span>
                                            </label>
                                            <div class="col-12 pl-0">
                                                <input type="text" class="form-control"
                                                    placeholder="{{'MANAGE-CUSTOMER.NAME' | translate}}"
                                                    formControlName="first_name"
                                                    (change)="setValidatorCommittee(i)"
                                                    [ngClass]="{ 'is-invalid': submitted &&
                                                        formGroup.get('committees.'+i+'.first_name').invalid}">
                                            </div>
                                            <div *ngIf="submitted && formGroup.get('committees.'+i+'.first_name').invalid"
                                                class="text-red pt-1 pl-1">
                                                <span
                                                    *ngIf="formGroup.controls['committees'].controls[i].get('first_name').errors?.required">
                                                    {{"MANAGE-CUSTOMER.VALID-NAME-COMMITTEE"|translate}}</span>
                                            </div>
                                        </div>

                                        <!-- commitee last name -->
                                        <div class="form-group col">
                                            <label class="col-form-label">
                                                {{"MANAGE-CUSTOMER.LAST-NAME" | translate}}
                                                <span class="text-red" *ngIf="i === 0">*</span>
                                            </label>
                                            <div class="col-12 pl-0">
                                                <input type="text" class="form-control"
                                                    placeholder="{{'MANAGE-CUSTOMER.LAST-NAME' | translate}}"
                                                    formControlName="last_name" (change)="setValidatorCommittee(i)"
                                                    [ngClass]="{ 'is-invalid': submitted &&
                                                        formGroup.get('committees.'+i+'.last_name').invalid}">
                                            </div>
                                            <div *ngIf="submitted && formGroup.get('committees.'+i+'.last_name').invalid"
                                                class="text-red pt-1 pl-1">
                                                <span
                                                    *ngIf="formGroup.controls['committees'].controls[i].get('last_name').errors?.required">
                                                    {{"MANAGE-CUSTOMER.VALID-LAST-NAME-COMMITTEE"|translate}}</span>
                                            </div>
                                        </div>
                                    </div>
                                      <div class="row px-3" >

                                        <div class="form-group col-12 col-md-6">
                                            <label class="col-form-label">
                                                {{"MANAGE-CUSTOMER.EMAIL" | translate}}
                                                <span class="text-red" *ngIf="i === 0">*</span>
                                            </label>
                                            <div class="col-12 pl-0">
                                                <input type="text" class="form-control"
                                                    placeholder="{{'MANAGE-CUSTOMER.EMAIL' | translate}}"
                                                    formControlName="email" (change)="setValidatorCommittee(i)"
                                                    [ngClass]="{ 'is-invalid': submitted &&
                                                        formGroup.get('committees.'+i+'.email').invalid}">
                                            </div>
                                            <div *ngIf="submitted && formGroup.get('committees.'+i+'.email').invalid"
                                                class="text-red pt-1 pl-1">
                                                <span *ngIf="formGroup.get('committees.'+i+'.email')?.errors?.required">
                                                    {{"MANAGE-CUSTOMER.VALID-EMAIL"|translate}}</span>
                                            </div>
                                            <span *ngIf="formGroup.get('committees.'+i+'.email')?.errors?.pattern"
                                                class="text-red pt-1 pl-1">
                                                {{"CREATE-USER.EMAIL-PATTERN"|translate}}
                                            </span>
                                        </div>

                                        <div class="form-group col-12 col-md-6">
                                            <label class="col-form-label">
                                                {{"MANAGE-CUSTOMER.NUMBER-PHONE" | translate}}
                                                <span class="text-red" *ngIf="i === 0">*</span>
                                            </label>
                                            <div class="col-12 pl-0">
                                                <input type="text" [imask]="{mask: '000-000-0000'}"
                                                    [unmask]="true"
                                                    class="form-control"
                                                    placeholder="{{'MANAGE-CUSTOMER.NUMBER-PHONE' | translate}}"
                                                    formControlName="phone_number"
                                                    (change)="setValidatorCommittee(i)" [ngClass]="{ 'is-invalid': submitted &&
                                                            formGroup.get('committees.'+i+'.phone_number').invalid}">
                                            </div>
                                            <div *ngIf="submitted && formGroup.get('committees.'+i+'.phone_number').invalid"
                                                class="text-red pt-1 pl-1">
                                                <span
                                                    *ngIf="formGroup.controls['committees']
                                                    .controls[i].get('phone_number').errors?.required">
                                                    {{"MANAGE-CUSTOMER.VALID-NUMBER-PHONE"|translate}}</span>
                                            </div>
                                          <span class="text-red pt-1 pl-1"
                                                    *ngIf="formGroup.controls['committees']
                                                    .controls[i].get('phone_number').hasError('minlength')">
                                                    {{"MANAGE-CUSTOMER.VALID-NUMBER-PHONE-MIN"|translate}}
                                              </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- contact -->
                        <div class="form-group col-12 col-md-12" *ngIf="formGroup.value.type_user === 'company'"
                            appSetTheme type="headerTable">
                            <label class="col-form-label content-label">
                                {{"MANAGE-CUSTOMER.CONTACT" | translate}}
                            </label>
                        </div>

                        <div class="row px-3">
                            <!-- contact title -->
                            <div class="form-group col-12 col-md-2" *ngIf="formGroup.value.type_user === 'company'"
                                style="padding-right: 0px;">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.NAME-PREFIX" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <ng-select [items]="prefixItem"
                                        placeholder="{{'MANAGE-CUSTOMER.SELECT-TYPE' | translate}}" class="custom"
                                        [bindLabel]="translated === 'en'? 'label_en':'label'" bindValue="value"
                                        [clearable]="false" formControlName="contact_prefix"
                                        [notFoundText]="'SELECT.No items found'|translate"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.contact_prefix.invalid}">
                                    </ng-select>
                                </div>
                                <div *ngIf="submitted && formGroup.controls.contact_prefix.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.contact_prefix.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-PREFIX-COMMITTEE"|translate}}</span>
                                </div>
                            </div>
                            <!-- contact title other -->
                            <div class="form-group col-12 col-md-2" *ngIf="formGroup.value.type_user === 'company'
                                        && formGroup.value.contact_prefix === 'other'" style="padding-right: 0px;">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.OTHER" | translate}}
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control"
                                        placeholder="{{'MANAGE-CUSTOMER.PLEASE-SPECIFY' | translate}}"
                                        formControlName="contact_prefix_other">
                                </div>
                            </div>

                            <!-- contact name -->
                            <div class="form-group col" *ngIf="formGroup.value.type_user === 'company'">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.NAME" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control"
                                        placeholder="{{'MANAGE-CUSTOMER.NAME' | translate}}"
                                        formControlName="contact_first_name"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.contact_first_name.invalid}">
                                </div>
                                <div *ngIf="submitted && formGroup.controls.contact_first_name.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.contact_first_name.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-NAME-COMMITTEE"|translate}}</span>
                                </div>
                            </div>

                            <!-- contact last name -->
                            <div class="form-group col" *ngIf="formGroup.value.type_user === 'company'">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.LAST-NAME" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control"
                                        placeholder="{{'MANAGE-CUSTOMER.LAST-NAME' | translate}}"
                                        formControlName="contact_last_name"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.contact_last_name.invalid}">
                                </div>
                                <div *ngIf="submitted && formGroup.controls.contact_last_name.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.contact_last_name.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-LAST-NAME-COMMITTEE"|translate}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="row px-3">
                            <!-- contact email -->
                            <div class="form-group col-12 col-md-6" *ngIf="formGroup.value.type_user === 'company'">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.EMAIL" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control"
                                        placeholder="{{'MANAGE-CUSTOMER.EMAIL' | translate}}"
                                        formControlName="contact_email"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.contact_email.invalid}">
                                </div>
                                <div *ngIf="submitted && formGroup.controls.contact_email.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.contact_email.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-EMAIL"|translate}}</span>
                                </div>
                                <span *ngIf="formGroup.controls.contact_email.errors?.pattern"
                                    class="text-red pt-1 pl-1">
                                    {{"CREATE-USER.EMAIL-PATTERN"|translate}}
                                </span>
                            </div>

                            <!-- contact phone number -->
                            <div class="form-group col-12 col-md-6" *ngIf="formGroup.value.type_user === 'company'">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.NUMBER-PHONE" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" [imask]="{mask: '000-000-0000'}" class="form-control"
                                        [unmask]="true"
                                        placeholder="{{'MANAGE-CUSTOMER.NUMBER-PHONE' | translate}}"
                                        formControlName="contact_phone_number"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.contact_phone_number.invalid}">
                                </div>
                                <div *ngIf="submitted && formGroup.controls.contact_phone_number.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.contact_phone_number.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-NUMBER-PHONE"|translate}}</span>
                                </div>
                                <span class="text-red pt-1 pl-1"
                                    *ngIf="formGroup.controls.contact_phone_number?.hasError('minlength')">
                                    {{"MANAGE-CUSTOMER.VALID-NUMBER-PHONE-MIN"|translate}}
                                </span>
                            </div>
                        </div>

                        <!-- address title -->
                        <div class="form-group col-12 col-md-12" appSetTheme type="headerTable">
                            <label class="col-form-label content-label"
                                *ngIf="formGroup.value.type_user === 'individual'">
                                {{"MANAGE-CUSTOMER.ADDRESS-ID" | translate}}
                            </label>
                            <label class="col-form-label content-label" *ngIf="formGroup.value.type_user === 'company'">
                                {{"MANAGE-CUSTOMER.USE-ADDRESS-COMPANY" | translate}}
                            </label>
                        </div>

                        <div class="row px-3">
                            <!-- house no -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.HOUSE-NO" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control" (ngModelChange)="onChangeHouseNo($event)"
                                        placeholder="{{'MANAGE-CUSTOMER.HOUSE-NO' | translate}}"
                                        formControlName="house_no"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.house_no.invalid}">
                                </div>
                                <div *ngIf="submitted && formGroup.controls.house_no.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.house_no.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-HOUSE-NO"|translate}}</span>
                                </div>
                            </div>

                            <!-- village no -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.VILLAGE-NO" | translate}}
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control" (ngModelChange)="onChangeVillageNo($event)"
                                        placeholder="{{'MANAGE-CUSTOMER.VILLAGE-NO' | translate}}"
                                        formControlName="village_no"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.village_no.invalid}">
                                </div>
                                <div *ngIf="submitted && formGroup.controls.village_no.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.village_no.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-MOO"|translate}}</span>
                                </div>
                            </div>

                            <!-- road -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.ROAD" | translate}}
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control" (ngModelChange)="onChangeRoad($event)"
                                        placeholder="{{'MANAGE-CUSTOMER.ROAD' | translate}}" formControlName="road"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.road.invalid}">
                                </div>
                                <div *ngIf="submitted && formGroup.controls.road.invalid" class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.road.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-ROAD"|translate}}</span>
                                </div>
                            </div>

                            <!-- province -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.PROVINCE" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <ng-select [items]="proviceItem"
                                        placeholder="{{'MANAGE-CUSTOMER.PROVINCE' | translate}}" class="custom"
                                        [bindLabel]="translated === 'en'? 'context.value_eng':'label'" bindValue="value"
                                        formControlName="province" [clearable]="false" (clear)="clearValueAddress()"
                                        (ngModelChange)="getItemDistrict($event)"
                                        (ngModelChange)="onChangeProvince($event)"
                                        [notFoundText]="'SELECT.No items found'|translate"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.province.invalid}">
                                    </ng-select>
                                </div>
                                <div *ngIf="submitted && formGroup.controls.province.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.province.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-PROVINCE"|translate}}</span>
                                </div>
                            </div>

                            <!-- district -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.DISTRICT" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <ng-select [items]="districtItem"
                                        placeholder="{{'MANAGE-CUSTOMER.DISTRICT' | translate}}" class="custom"
                                        [bindLabel]="translated === 'en'? 'context.value_eng':'label'" bindValue="value"
                                        formControlName="district" [clearable]="false"
                                        (ngModelChange)="getItemSubDistrict($event)"
                                        (ngModelChange)="onChangeDistrict($event)"
                                        [notFoundText]="'SELECT.No items found'|translate"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.district.invalid}">
                                    </ng-select>
                                </div>
                                <div *ngIf="submitted && formGroup.controls.district.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.district.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-DISTRICT"|translate}}</span>
                                </div>
                            </div>

                            <!-- sub district -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.SUB-DISTRICT" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <ng-select [items]="subdistrictitem"
                                        placeholder="{{'MANAGE-CUSTOMER.SUB-DISTRICT' | translate}}" class="custom"
                                        [bindLabel]="translated === 'en'? 'context.value_eng':'label'" bindValue="value"
                                        formControlName="sub_district" [clearable]="false"
                                        (ngModelChange)="getPostralCode($event)" (ngModelChange)="onChangeSubDistrict($event)"
                                        [notFoundText]="'SELECT.No items found'|translate"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.sub_district.invalid}">
                                    </ng-select>
                                </div>
                                <div *ngIf="submitted && formGroup.controls.sub_district.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.sub_district.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-SUBDISTRICT"|translate}}</span>
                                </div>
                            </div>

                            <!-- postral code -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.POSTRAL-CODE" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                  <ng-select [items]="postalCodeDropdown"
                                        placeholder="{{'MANAGE-CUSTOMER.POSTRAL-CODE' | translate}}" class="custom"
                                        [bindLabel]="'label'" bindValue="value"
                                        [clearable]="false"
                                        formControlName="postal_code"
                                              (ngModelChange)="onChangePostalCode($event)"
                                        [notFoundText]="'SELECT.No items found'|translate"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.postal_code.invalid}">
                                   </ng-select>
                                </div>
                                <div *ngIf="submitted && formGroup.controls.postal_code.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.postal_code.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-POSTRAL-CODE"|translate}}</span>
                                </div>
                            </div>
                        </div>

                        <!-- checkbox address -->
                        <div class="form-group col-12 col-md-12" appSetTheme type="headerTable">
                            <label class="col-form-label content-label">
                                {{"MANAGE-CUSTOMER.ADDRESS-CONTRACT" | translate}}
                            </label>
                        </div>

                        <div class="row px-3">
                            <!-- check box -->
                            <div class="form-group col-12 col-md-12">
                                <div class="pl-0 row">
                                    <div class="col-auto form-check form-check-inline pointer">
                                        <input class="form-check-input" id="from_address" type="checkbox"
                                            [(ngModel)]="statusAddress"
                                            (click)="statusAddress = !statusAddress;onSelectCopyAddress($event)"
                                            [ngModelOptions]="{standalone: true}"
                                            [disabled]="formGroup.value.card_type==='passport_id'">
                                        <label class="col-form-label"
                                            *ngIf="formGroup.value.type_user === 'individual'">
                                            {{"MANAGE-CUSTOMER.USE-ADDRESS-ID" | translate}}
                                        </label>
                                        <label class="col-form-label" *ngIf="formGroup.value.type_user === 'company'">
                                            {{"MANAGE-CUSTOMER.USE-ADDRESS-COMPANY-FROM" | translate}}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <!-- house no contact -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.HOUSE-NO" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control"
                                        placeholder="{{'MANAGE-CUSTOMER.HOUSE-NO' | translate}}"
                                        formControlName="contact_house_no"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.contact_house_no.invalid}">
                                </div>
                                <div *ngIf="submitted && formGroup.controls.contact_house_no.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.contact_house_no.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-HOUSE-NO"|translate}}</span>
                                </div>
                            </div>

                            <!-- village no contact -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.VILLAGE-NO" | translate}}
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control"
                                        placeholder="{{'MANAGE-CUSTOMER.VILLAGE-NO' | translate}}"
                                        formControlName="contact_village_no"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.contact_village_no.invalid}">
                                </div>
                                <div *ngIf="submitted && formGroup.controls.contact_village_no.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.contact_village_no.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-MOO"|translate}}</span>
                                </div>
                            </div>

                            <!-- road contact -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.ROAD" | translate}}
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control"
                                        placeholder="{{'MANAGE-CUSTOMER.ROAD' | translate}}"
                                        formControlName="contact_road"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.contact_road.invalid}">
                                </div>
                                <div *ngIf="submitted && formGroup.controls.contact_road.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.contact_road.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-ROAD"|translate}}</span>
                                </div>
                            </div>

                            <!-- province contact -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.PROVINCE" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <ng-select [items]="proviceItem"
                                        placeholder="{{'MANAGE-CUSTOMER.PROVINCE' | translate}}" class="custom"
                                        [bindLabel]="translated === 'en'? 'context.value_eng':'label'" bindValue="value"
                                        [clearable]="false" formControlName="contact_province"
                                        (clear)="clearValueCopyAddress()"
                                        (ngModelChange)="getItemDistrictContact($event)"
                                        [notFoundText]="'SELECT.No items found'|translate"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.contact_province.invalid}">
                                    </ng-select>
                                </div>
                                <div *ngIf="submitted && formGroup.controls.contact_province.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.contact_province.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-PROVINCE"|translate}}</span>
                                </div>
                            </div>

                            <!-- district contact -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.DISTRICT" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <ng-select [items]="districtItem"
                                        placeholder="{{'MANAGE-CUSTOMER.DISTRICT' | translate}}" class="custom"
                                        [bindLabel]="translated === 'en'? 'context.value_eng':'label'" bindValue="value"
                                        [clearable]="false" formControlName="contact_district"
                                        (ngModelChange)="getItemSubDistrictContact($event)"
                                        [notFoundText]="'SELECT.No items found'|translate"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.contact_district.invalid}">
                                    </ng-select>
                                </div>
                                <div *ngIf="submitted && formGroup.controls.contact_district.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.contact_district.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-DISTRICT"|translate}}</span>
                                </div>
                            </div>

                            <!-- sub district contact -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.SUB-DISTRICT" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <ng-select [items]="subdistrictitem"
                                        placeholder="{{'MANAGE-CUSTOMER.SUB-DISTRICT' | translate}}" class="custom"
                                        [bindLabel]="translated === 'en'? 'context.value_eng':'label'" bindValue="value"
                                        [clearable]="false" (ngModelChange)="getPostralCodeContact($event)"
                                        formControlName="contact_sub_district"
                                        [notFoundText]="'SELECT.No items found'|translate"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.contact_sub_district.invalid}">
                                    </ng-select>
                                </div>
                                <div *ngIf="submitted && formGroup.controls.contact_sub_district.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.contact_sub_district.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-SUBDISTRICT"|translate}}</span>
                                </div>
                            </div>

                            <!-- postral code contact -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.POSTRAL-CODE" | translate}}
                                    <span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                  <ng-select [items]="postalCodeContactDropdown"
                                        placeholder="{{'MANAGE-CUSTOMER.POSTRAL-CODE' | translate}}" class="custom"
                                        [bindLabel]="'label'" bindValue="value"
                                        [clearable]="false"
                                        formControlName="contact_postal_code"
                                        [notFoundText]="'SELECT.No items found'|translate"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.contact_postal_code.invalid}">
                                   </ng-select>
                                </div>
                                <div *ngIf="submitted && formGroup.controls.contact_postal_code.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.contact_postal_code.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-POSTRAL-CODE"|translate}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="row px-3">
                            <!-- email -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label" *ngIf="formGroup.value.type_user === 'individual'">
                                    {{"MANAGE-CUSTOMER.MAIN-EMAIL" | translate}}
                                </label>
                                <label class="col-form-label" *ngIf="formGroup.value.type_user === 'company'">
                                    {{"MANAGE-CUSTOMER.EMAIL-COMPANY" | translate}}
                                </label>
                                <div class="col-12 pl-0">
                                    <input *ngIf="formGroup.value.type_user === 'individual'" type="text"
                                        class="form-control" placeholder="{{'MANAGE-CUSTOMER.MAIN-EMAIL' | translate}}"
                                        formControlName="email"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.email.invalid}">
                                    <input *ngIf="formGroup.value.type_user === 'company'" type="text"
                                        class="form-control"
                                        placeholder="{{'MANAGE-CUSTOMER.EMAIL-COMPANY' | translate}}"
                                        formControlName="email"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.email.invalid}">
                                </div>
                                <div *ngIf="submitted && formGroup.controls.email.invalid" class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.email.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-EMAIL"|translate}}</span>
                                </div>
                                <span *ngIf="formGroup.controls.email.errors?.pattern" class="text-red pt-1 pl-1">
                                    {{"CREATE-USER.EMAIL-PATTERN"|translate}}
                                </span>
                            </div>

                            <!-- secondary email -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.SECONDARY-EMAIL" | translate}}
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control"
                                        placeholder="{{'MANAGE-CUSTOMER.SECONDARY-EMAIL' | translate}}"
                                        formControlName="secondary_email">
                                </div>
                                <span *ngIf="formGroup.controls.secondary_email.errors?.pattern"
                                    class="text-red pt-1 pl-1">
                                    {{"CREATE-USER.EMAIL-PATTERN"|translate}}
                                </span>
                            </div>

                            <!-- number phone -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.NUMBER-PHONE" | translate}}
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" [imask]="{mask: '000-000-0000'}" class="form-control"
                                        [unmask]="true"
                                        placeholder="{{'MANAGE-CUSTOMER.NUMBER-PHONE' | translate}}"
                                        formControlName="phone_number"
                                        [ngClass]="{ 'is-invalid':formGroup.controls.phone_number.invalid}">
                                </div>
                                  <span class="text-red pt-1 pl-1"
                                    *ngIf="formGroup.controls.phone_number?.hasError('minlength')">
                                  {{"MANAGE-CUSTOMER.VALID-NUMBER-PHONE-MIN"|translate}}
                                  </span>
                            </div>

                            <!-- secondary number phone -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.SECONDARY-NUMBER-PHONE" | translate}}
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" [imask]="{mask: '000-000-0000'}" class="form-control"
                                        [unmask]="true"
                                        placeholder="{{'MANAGE-CUSTOMER.SECONDARY-NUMBER-PHONE' | translate}}"
                                        formControlName="secondary_phone_number">
                                </div>
                                <span class="text-red pt-1 pl-1"
                                    *ngIf="formGroup.controls.secondary_phone_number?.hasError('minlength')">
                                    {{"MANAGE-CUSTOMER.VALID-NUMBER-PHONE-MIN"|translate}}
                                </span>
                            </div>

                            <!-- location -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.LOCATION" | translate}}<span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <ng-select [items]="locationItem"
                                        placeholder="{{'MANAGE-CUSTOMER.LOCATION' | translate}}" class="custom"
                                        [bindLabel]="'label'" bindValue="value" [clearable]="false"
                                        formControlName="location" (change)="getItemProperty($event.value,true)"
                                        [notFoundText]="'SELECT.No items found'|translate"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.location.invalid}">
                                    </ng-select>
                                </div>
                                <div *ngIf="submitted && formGroup.controls.location.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.location.errors.required">
                                        {{"MASTER-DATA.VALID-LOCATIONS"|translate}}</span>
                                </div>
                            </div>

                            <!-- type property -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MANAGE-CUSTOMER.TYPE-PROPERTY" | translate}}<span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <ng-select [items]="propertyItem"
                                        placeholder="{{'MANAGE-CUSTOMER.TYPE-PROPERTY' | translate}}" class="custom"
                                        [bindLabel]="translated === 'en'? 'context.name_en':'context.name_th'"
                                        bindValue="value" [clearable]="false" formControlName="property"
                                        (change)="getBuildingItem($event.value,true)"
                                        [notFoundText]="'SELECT.No items found'|translate"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.property.invalid}">
                                    </ng-select>
                                </div>
                                <div *ngIf="submitted && formGroup.controls.property.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.property.errors.required">
                                        {{"MANAGE-CUSTOMER.VALID-TYPE-PROPERTY"|translate}}</span>
                                </div>
                            </div>

                            <!-- building -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"MASTER-DATA.BUILDING"|translate}}<span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <ng-select *ngIf="!refreshDropdown" [items]="itemBuilding"
                                        placeholder="{{'MASTER-DATA.BUILDING' | translate}}" class="custom"
                                        [bindLabel]="translate.currentLang === 'en' ? 'context.name': 'context.name'"
                                        bindValue="value" [clearable]="false" formControlName="building"
                                        [notFoundText]="'SELECT.No items found'|translate"
                                        [ngClass]="{ 'is-invalid': submitted && formGroup.controls.building.invalid}">
                                    </ng-select>
                                </div>
                                <div *ngIf="submitted && formGroup.controls.building.invalid"
                                    class="text-red pt-1 pl-1">
                                    <span *ngIf="formGroup.controls.building.errors.required">
                                        {{"MASTER-DATA.VALID-BUILDINGS"|translate}}</span>
                                </div>
                            </div>

                          <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"LANDING.The room number you wish to reserve"|translate}}<span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control"
                                           placeholder="{{'LANDING.The room number you wish to reserve'|translate}}"
                                           formControlName="room_reserve"
                                           [ngClass]="{ 'is-invalid': submitted && formGroup.controls.room_reserve.invalid}"/>
                                </div>
                                <div *ngIf="submitted && formGroup.controls.room_reserve?.invalid" class="text-red pt-1 pl-1">
                                    <span *ngIf="translate.currentLang === 'en' else required_th">
                                          {{'LANDING.The room number you wish to reserve'|translate}} {{'LANDING.IS-REQUIRED'|translate}}
                                    </span>
                                    <ng-template #required_th>
                                        {{'LANDING.IS-REQUIRED'|translate}}{{'LANDING.The room number you wish to reserve'|translate}}
                                    </ng-template>
                                </div>
                            </div>

                            <!-- Number of Occupants -->
                            <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">{{ 'LANDING.Number of Occupants' | translate }}</label>
                                <div class="col-12 pl-0">
                                    <ng-select [items]="NumberOfOccupantList"
                                        *ngIf="!loadingDropdown"
                                        class="custom-selector"
                                        [bindLabel]="translate.currentLang === 'en' ? 'label_en': 'label'"
                                        [bindValue]="'value'"
                                        [clearable]="false"
                                        placeholder="{{'LANDING.Select' | translate}}"
                                        typeToSearchText="{{ 'user.type-search' | translate }}"
                                        formControlName="number_of_occupants">
                                    </ng-select>
                                </div>
                            </div>

                          <div class="form-group col-12 col-md-6">
                                <label class="col-form-label">
                                    {{"LANDING.Duration of the contract"|translate}}<span class="text-red">*</span>
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control"
                                           placeholder="{{'LANDING.Duration of the contract'|translate}}"
                                           formControlName="duration_contract"
                                           [ngClass]="{ 'is-invalid': submitted && formGroup.controls.duration_contract.invalid}"/>
                                </div>
                                <div *ngIf="submitted && formGroup.controls.duration_contract?.invalid" class="text-red pt-1 pl-1">
                                    <span *ngIf="translate.currentLang === 'en' else required_th">
                                          {{'LANDING.The room number you wish to reserve'|translate}} {{'LANDING.IS-REQUIRED'|translate}}
                                    </span>
                                    <ng-template #required_th>
                                        {{'LANDING.IS-REQUIRED'|translate}}{{'LANDING.Duration of the contract'|translate}}
                                    </ng-template>
                                </div>
                            </div>
                          <div [ngClass]="formGroup.value.type_user === 'company' ? 'form-group col-lg-12' : 'form-group col-12 col-md-6'">
                                <label class="col-form-label">
                                    {{"LANDING.Additional details"|translate}}
                                </label>
                                <div class="col-12 pl-0">
                                    <input type="text" class="form-control"
                                           placeholder="{{'LANDING.Additional details'|translate}}"
                                           formControlName="additional_details"
                                           [ngClass]="{ 'is-invalid': submitted && formGroup.controls.additional_details.invalid}"/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </div>

    </div>
</div>

<!-- modal confirm edit -->
<ng-template #editDepartment let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">
            {{"DEPARTMENT.Edit Confirmation"| translate}}
        </h4>
    </div>
    <div class="modal-body text-center f-18">
        {{"DEPARTMENT.Are you sure you want to edit this item"| translate}}
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline btn-confirm" [style.--c]="themeList?.header_table_color" (click)="c()">
            {{'MEMOS.CANCEL'|translate}}
        </button>
        <button class="btn btn-confirm delete-bt" (click)="submit()" appSetTheme type="button">
            {{"MEMOS.CONFIRM"| translate}}
        </button>
    </div>
</ng-template>

<!-- upload image -->
<div class="modal-content p-10">
    <ng-template #cropImgUser let-c="close" let-d="dismiss">
        <div class="modal-header align-items-center">
            <div class="col">
                <h4 class="mb-1">{{"CREATE-USER.EDIT-PHOTO" | translate}}</h4>
            </div>
            <div class="col-auto">
                <i class="fas fa-times pointer size-24px" data-dismiss="modal" (click)="d('Cross click')"></i>
            </div>
        </div>
        <div class="modal-body">
            <div [ngClass]="{'d-none': !photo || imageChangedEventPhoto || imageURLPhoto}">
                <div class="row justify-content-center">
                    <div class="col-4 align-self-center text-center bg-white">
                        <img alt="image" class="img-fluid img-thumbnail blend-hard-light" [src]="photo">
                        <h6 class="mt-2 font-weight-bold">{{"CREATE-USER.CURRENT-SIGNATURE" | translate}}</h6>
                    </div>
                </div>
            </div>
            <div class="row" [ngClass]="{'d-none': photo && !imageChangedEventPhoto}">
                <div class="col-6 align-self-center text-center">
                    <img alt="image" class="img-fluid img-thumbnail" *ngIf="!imageChangedEventPhoto && !imageURLPhoto"
                        src="assets/images/original.png">
                    <image-cropper *ngIf="imageChangedEventPhoto || imageURLPhoto"
                        [imageChangedEvent]="imageChangedEventPhoto" [imageURL]="imageURLPhoto"
                        [maintainAspectRatio]="true" [cropper]="cropper" (imageCropped)="imageCroppedPhoto($event)"
                        (cropperReady)="onCropperReady($event)" imageFile (loadImageFailed)="loadImageFailedPhoto()"
                        format="png" outputType="both" class="img-thumbnail" #imageCropper>
                    </image-cropper>
                    <input type="file" accept="image/*" class="d-none" (change)="filePhotoChangeEvent($event)"
                        #filePhoto>
                    <h6 class="mt-2 font-weight-bold">{{"CREATE-USER.ORIGINAL-IMAGE" | translate}}</h6>
                </div>
                <div class="col-6 align-self-center text-center">
                    <img alt="image" class="img-fluid img-thumbnail" src="assets/images/preview.png"
                        *ngIf="!croppedImagePhoto">
                    <div class="img-thumbnail" *ngIf="croppedImagePhoto">
                        <div class="overflow-hidden h-100pc">
                            <div class="d-inline-block align-middle text-center h-100pc bg-white">
                                <span class="d-inline-block align-middle h-100pc m-t-1"></span>
                                <img alt="image" class="img-fluid align-middle mh-100pc outline-grey blend-hard-light"
                                    [src]="croppedImagePhoto">
                            </div>
                        </div>
                    </div>
                    <h6 class="mt-2 font-weight-bold">{{"CREATE-USER.SAMPLE-IMAGE" | translate}}</h6>
                </div>
            </div>
        </div>
        <div class="modal-footer" style="position: relative">
            <div class="message-box"><span class="message-error">{{ errorMsg | translate }}</span></div>
            <button type="button" class="btn btn-outline-secondary" (click)="filePhoto.click()">{{"CREATE-USER.UPLOAD" |
                translate}}</button>
            <button type="button" class="btn btn-success" data-dismiss="modal"
                (click)="savePhoto()">{{"CREATE-USER.SAVE" |
                translate}}</button>
        </div>
    </ng-template>
</div>
