import { HttpParams } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngxs/store';
import {
  BehaviorSubject,
  combineLatest,
  concat,
  merge,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { DropdownItem } from '../../../../../shared/models/common.model';
import { User } from '../../../../../shared/models/user.models';
import {
  DropdownResponse,
  DropdownService,
} from '../../../../../shared/service/dropdown.service';
import { CertificateStatus } from '../../../../certificate/shared/certificate.model';
import { Member } from '../../../../loa/shared/loa.model';
import { ApproverDropdown } from '../../../model/memo.model';
import { DdocLawSectionObj } from '../../upload-memos/loa-customizer/signing-law-section-radio/signing-law-section-radio.component';
import { MemoService } from '../../../service/memo.service';
import { ReservationResults } from '../../../model/reservationContract.model';
import { TranslateService } from '@ngx-translate/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-approval-dropdown',
  templateUrl: './approval-dropdown.component.html',
  styleUrls: ['./approval-dropdown.component.scss'],
})
export class ApprovalDropdownComponent implements OnChanges, OnInit {
  certificateStatus: CertificateStatus = null;
  people$: Observable<ApproverDropdown[]>;
  peopleInput$ = new Subject<string>();
  peopleLoading = false;
  memoType = '';
  resultReservation: ReservationResults = null;
  formGroup: FormGroup;
  @Input() personType = 'all';
  @Input() clearable = false;
  @Input() selectedApprovals: number[] = [];
  @Input() approval: Partial<Member>;
  @Input() disabled = false;
  @Input() disabledApprover = false;
  @Input() freeLawSelection = false;
  @Input() enableAttachSignature = false;
  @Input() ddocEnable = false;
  @Input() ddocLawSection: DdocLawSectionObj;
  @Input() allowLOAChange = true;
  @Output() approvalChange = new EventEmitter();
  @Output() ddocLawSectionChange =
    new EventEmitter<DdocLawSectionObj>();

  destroy$ = new Subject();
  ccList$: Observable<any>;
  ccListInput$ = new Subject<string>();
  loading$ = new BehaviorSubject<boolean>(false);
  ccGroupList: any[] = [];
  @Input() selectedNgSelect = '';
  @Input() submitted: boolean;

  lang$ = this.translate.onLangChange.pipe(
    takeUntil(this.destroy$),
    pluck('lang'),
    distinctUntilChanged(),
  );

  private _excludeCCIds = [];
  excludeCCIds$ = new BehaviorSubject<number[]>([]);
  @Input() set excludeCCIds(v: number[]) {
    this._excludeCCIds = v;
    this.excludeCCIds$.next(this._excludeCCIds);
  }
  get excludeCCIds(): number[] {
    return this._excludeCCIds;
  }
  @Output() selected = new EventEmitter<any>();
  constructor(
    private dropdownService: DropdownService,
    private store: Store,
    private memoService: MemoService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
  ) {
    this.store.subscribe((state) => {
      this.memoType = state.memoCreationData.memo_type;
      this.personType = state.memoCreationData.person_type;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ddocEnable) {
      if (!changes.ddocEnable.currentValue) {
        this.ddocLawSection = { lawSection: null };
      }
    }
    this.memoService.getReservation().subscribe((res) => {
      this.resultReservation = res;
    });
  }

  ngOnInit(): void {
    this.setPeople();
    this.getCCList();
    this.formGroup = this.formBuilder.group({
      approval: [null, Validators.required],
      ccList: [null, Validators.required],
    });
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setPeople(): void {
    this.people$ = concat(
      of([] as ApproverDropdown[]),
      this.peopleInput$.pipe(
        distinctUntilChanged(),
        filter((term) => term != null && term !== ''),
        debounceTime(300),
        tap(() => (this.peopleLoading = true)),
        switchMap((query) => {
          const params: { [k: string]: any } = {
            type: 'people_from_type',
            all: 'true',
            group: 'General',
            with_signature: true,
            person_type: this.personType,
            include_chod: true,
            query,
          };

          if (this.enableAttachSignature) {
            params.with_signature = true;
          }

          return this.dropdownService.getDropdown(params).pipe(
            map((obj) => {
              if (obj.hasOwnProperty('people_from_type')) {
                return obj.people_from_type.map(
                  (
                    people_from_type: DropdownItem<
                      number,
                      PeopleDropdownContext
                    >,
                  ) => {
                    const approver: ApproverDropdown = {
                      person: people_from_type.value,
                      full_name: people_from_type.label,
                    };
                    return approver;
                  },
                );
              } else if (obj.hasOwnProperty('people')) {
                return obj.people.map(
                  (
                    people: DropdownItem<
                      number,
                      PeopleDropdownContext
                    >,
                  ) => {
                    const approver: ApproverDropdown = {
                      person: people.value,
                      full_name: people.label,
                    };
                    if (this.enableAttachSignature) {
                      approver.signature = people.context.signature;
                    }
                    return approver;
                  },
                );
              } else {
                return [];
              }
            }),
            map((people) => {
              return people.filter(
                (person) =>
                  !this.selectedApprovals.includes(person.person),
              );
            }),
          );
        }),
        catchError(() => of([] as ApproverDropdown[])),
        tap(() => (this.peopleLoading = false)),
      ),
    );
  }

  onDropdownChange(dropdown: ApproverDropdown): void {
    this.approvalChange.emit(dropdown);
    this.formGroup.controls.approval.setErrors(null);
  }
  async getCCList() {
    try {
      this.ccGroupList = (await this.getAllCCGroupList()).filter(
        (v) => !this.excludeCCIds.includes(v?.value),
      );
    } catch (err) {
      this.ccGroupList = [];
    }
    const initialList$ = of(this.ccGroupList);
    this.ccList$ = combineLatest([
      this.excludeCCIds$,
      merge(
        initialList$, // when user didn't search use initial
        this.ccListInput$.pipe(
          distinctUntilChanged(),
          debounceTime(500),
          tap(() => this.loading$.next(true)),
          switchMap((query) => {
            if (!query) {
              // when user delete query all, use initial
              return initialList$;
            }
            // when user type query, search from backend
            return this.queryCCList(query);
          }),
          catchError(() => of([])),
          tap(() => this.loading$.next(false)),
        ),
      ),
    ]).pipe(
      takeUntil(this.destroy$),
      map(([excludeIds, items]) => {
        // search items exclude with excludeIds
        return items.filter(
          ({ value }) => !excludeIds.includes(value),
        );
      }),
    );
  }

  queryCCList(query: string): Observable<any> {
    return this.dropdownService
      .getDropdown({
        type: 'cc',
        query,
      })
      .pipe(
        map((dropdown: DropdownResponse) => {
          return (dropdown.cc ?? []).filter(
            (v) => !this.excludeCCIds.includes(v?.value),
          );
        }),
      );
  }

  getAllCCGroupList(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.dropdownService
        .getDropdown({
          type: 'cc',
          exclude_cc_people: true,
          no_limit: true,
        })
        .pipe(
          tap(() => this.loading$.next(true)),
          catchError(() => of([])),
          map((dropdown: DropdownResponse) => dropdown.cc),
          tap(() => this.loading$.next(false)),
        )
        .subscribe({
          next: (ccGroups) => {
            resolve(ccGroups);
          },
          error: () => {
            reject([]);
          },
        });
    });
  }

  onCCChange(cc: CCList, ref: NgSelectComponent): void {
    if (!cc) {
      return;
    }
    const formattedCC = {
      id: cc?.id,
      content_type: cc?.content_type,
      cc_id: cc?.cc_id ?? cc?.value,
      label: cc?.label,
      is_cc_group: cc?.context?.is_group,
      context: cc?.context,
    };
    this.selected.emit(formattedCC);
    ref.handleClearClick();
    this.formGroup.controls.ccList.setErrors(null);
  }
}

interface PeopleDropdownContext extends Partial<User> {
  signature: string;
}
export type CCList = {
  label: string;
  value: number;
  context: Context;
  text: string;
  content_type: number;
  is_group: boolean;
  id: number;
  cc_id: number;
};

type Context = {
  text: string;
  content_type: number;
  is_group: boolean;
};
