import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ThemeList } from '../../service/theme.service';

export type ActionType =
  | 'approve'
  | 'reject'
  | 'terminate'
  | 'waiting_for_termination';

@Component({
  selector: 'app-approval-confirm-modal',
  templateUrl: './approval-confirm-modal.component.html',
  styleUrls: ['./approval-confirm-modal.component.scss'],
})
export class ApprovalConfirmModalComponent {
  @ViewChild('confirmationModal', { static: true })
  confirmationModal: ElementRef;

  /** Define the default value of the `confirm` EventEmitter */
  @Input() confirmEmitValue: any;
  @Input() isSaved: boolean;
  @Input() enableReason = true;
  @Input() reason: string;
  /** Set the label of reason above textarea */
  @Input() reasonLabel = 'APPROVAL.REASON';
  @Input() themeList: ThemeList;
  @Output() reasonChange = new EventEmitter<string>();

  @Output() confirm = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Input() memos;

  actionType: ActionType;

  constructor(
    public modalService: NgbModal,
    public translate: TranslateService,
  ) {}

  /**
   * Open the confirmation modal
   * @param action This parameter specify wording and UI.
   */
  open(action: ActionType): void {
    this.actionType = action;
    this.modalService.open(this.confirmationModal, {
      backdrop: 'static',
      centered: true,
    });
  }

  onReasonChange(): void {
    this.reasonChange.emit(this.reason);
  }

  submit(): void {
    this.modalService.dismissAll();
    this.confirm.emit(this.confirmEmitValue);
  }

  close(): void {
    this.modalService.dismissAll();
    this.closeModal.emit();
  }

  contentText() {
    if (this.actionType === 'approve') {
      return this.translate.instant('APPROVAL.APPROVE-CONFIRMATION');
    } else if (this.actionType === 'reject') {
      return this.translate.instant('APPROVAL.REJECT-CONFIRMATION');
    } else {
      return this.translate.instant(
        'APPROVAL.TERMINATE-CONFIRMATION',
      );
    }
  }
}

export interface ConfirmModalOptions {
  confirmEmitValue?: any;
  enableReason?: boolean;
  reasonLabel?: string;
}
