import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from 'src/app/shared/service/api.constant';

@Injectable({
  providedIn: 'root',
})
export class CustomerUserService {
  constructor(private http: ApiService) {}
  // Individual
  getItemIndividual(data?) {
    return this.http.get(API_URL.add_individual, data);
  }

  addIndividual(data) {
    return this.http.post(API_URL.add_individual, data);
  }

  editIndividual(id, data) {
    return this.http.patch(API_URL.add_individual + id + '/', data);
  }

  deleteIndividual(id) {
    return this.http.delete(API_URL.add_individual + id + '/');
  }

  getIndividual(id) {
    return this.http.get(API_URL.add_individual + id + '/');
  }

  approveStatusIndividual(status, data) {
    return this.http.post(
      API_URL.add_individual + status + '/',
      data,
    );
  }

  rejectOrRecoverStatusIndidual(status, data) {
    return this.http.patch(
      API_URL.add_individual + status + '/',
      data,
    );
  }

  getCustomerPersonDetail(id, data?) {
    return this.http.get(API_URL.customer_people + id + '/', data);
  }

  // company
  getItemCompany(data?) {
    return this.http.get(API_URL.add_company, data);
  }

  addCompany(data) {
    return this.http.post(API_URL.add_company, data);
  }

  editCompany(id, data) {
    return this.http.patch(API_URL.add_company + id + '/', data);
  }

  deleteCompany(id) {
    return this.http.delete(API_URL.add_company + id + '/');
  }

  getCompany(id) {
    return this.http.get(API_URL.add_company + id + '/');
  }

  approveStatusCompany(status, data) {
    return this.http.post(API_URL.add_company + status + '/', data);
  }

  rejectOrRecoverStatusCompany(status, data) {
    return this.http.patch(API_URL.add_company + status + '/', data);
  }

  getCustomerOperationLogList(params?: {
    [key: string]: string;
  }): Observable<any> {
    return this.http.get('/api/customer-operation-logs/', params);
  }

  getCheckIndividualData(params?) {
    return this.http.get(
      API_URL.add_individual + 'verify-email',
      params,
    );
  }

  getCheckCompanyData(params?) {
    return this.http.get(
      API_URL.add_company + 'verify-email',
      params,
    );
  }

  deleteCustomerPerson(id, data) {
    return this.http.patch(
      `${API_URL.customer_people}${id}/inactive-user/`,
      data,
    );
  }

  disableCustomerPerson(id) {
    return this.http.patch(
      API_URL.customer_people + id + '/disable-user/',
      {},
    );
  }

  enableCustomerPerson(id) {
    return this.http.patch(
      API_URL.customer_people + id + '/enable-user/',
      {},
    );
  }

  downloadCustomerReport() {
    return this.http.get('/api/people/person-csv/');
  }
}
