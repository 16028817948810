import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
moment.locale('th');
@Injectable({
  providedIn: 'root',
})
export class NgbThaiDateParserFormatter extends NgbDateParserFormatter {
  constructor() {
    super();
  }
  format(date: NgbDateStruct, forSendingToBackend = false): string {
    if (date == null) {
      return '';
    }
    const language = localStorage.getItem('lang');
    const d = moment({
      year: date.year,
      month: date.month - 1,
      date: date.day,
    });

    const toBuddhistYear = (moment, format) => {
      const christianYear = moment.format('YYYY');
      const buddhishYear = (parseInt(christianYear) + 543).toString();
      return moment
        .format(
          format
            .replace('YYYY', buddhishYear)
            .replace('YY', buddhishYear.substring(2, 4)),
        )
        .replace(christianYear, buddhishYear);
    };

    if (forSendingToBackend) {
      return d.isValid() ? d.format('YYYY-MM-DD') : '';
    } else {
      return toBuddhistYear(moment(d.toString()), 'DD/MM/YYYY');
    }
  }

  parse(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }

    let d = null;

    if (value.includes('-')) {
      d = moment(value, 'YYYY-MM-DD');
    } else {
      d = moment(value, 'DD/MM/YYYY');
    }

    return d.isValid()
      ? { year: d.year(), month: d.month() + 1, day: d.date() }
      : null;
  }
}
