import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'citizen',
})
export class CitizenPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    if (value) {
      return (
        {
          13: value?.replace(
            /(\d{1})(\d{4})(\d{5})(\d{2})/,
            '$1-$2-$3-$4',
          ),
        }[value.length] || value
      );
    }
  }
}
