<ng-select *ngIf="selectedNgSelect === 'people'"
    [items]="people$ | async"
    bindLabel="full_name"
    [loading]="peopleLoading"
    placeholder="{{'MEMOS.SELECT-APPROVER'|translate}}"
    [clearable]="clearable"
    [disabled]="disabled || disabledApprover"
    [(ngModel)]="approval"
    (change)="onDropdownChange($event);"
    [typeahead]="peopleInput$"
    [minTermLength]="2"
    typeToSearchText="{{ 'user.type-search' | translate }}"
    [notFoundText]="'SELECT.No items found'|translate"
    [ngClass]="{ 'is-invalid': submitted && formGroup.controls.approval.invalid}">
</ng-select>
<ng-select *ngIf="selectedNgSelect === 'ccpeople'"
    [items]="people$ | async"
    bindLabel="full_name"
    [loading]="peopleLoading"
    placeholder="{{'MEMOS.SELECT-APPROVER'|translate}}"
    [clearable]="clearable"
    [disabled]="disabled || disabledApprover"
    [(ngModel)]="approval"
    (change)="onDropdownChange($event);"
    [typeahead]="peopleInput$"
    [minTermLength]="2"
    typeToSearchText="{{ 'user.type-search' | translate }}"
    [notFoundText]="'SELECT.No items found'|translate"
    [ngClass]="{ 'is-invalid': submitted && formGroup.controls.approval.invalid}">
</ng-select>
<div *ngIf="formGroup.controls.approval.invalid && selectedNgSelect === 'ccpeople'  && submitted"
                            class="text-red pt-1 pl-1">
                            <span *ngIf="formGroup.controls.approval.errors.required">
                                {{"MEMOS.VALID-APPROVER"|translate}}</span>
                    </div>
<ng-select *ngIf="selectedNgSelect === 'ccList'"
  class="custom custom-multi"
  labelForId="cc"
  #selectRef
  placeholder="{{'MEMOS.SELECT'|translate}}"
  [multiple]="false"
  [items]="ccList$ | async"
  [typeahead]="ccListInput$"
  [loading]="loading$ | async"
  [typeToSearchText]="'user.type-search' | translate"
  [notFoundText]="'SELECT.No items found'|translate"
  [closeOnSelect]="true"
  [clearSearchOnAdd]="true"
  (change)="onCCChange($event, selectRef)"
  [ngClass]="{ 'is-invalid': submitted && formGroup.controls.ccList.invalid}">
  <ng-template ng-label-tmp
    let-item="item"
    let-clear="clear">
    <span class="ng-value-label">{{ (lang$|async) === 'en' ? item.context?.name_en || item.label : item.label }}</span>
    <span class="ng-value-icon right"
      (click)="clear(item)"
      aria-hidden="true">×</span>
  </ng-template>

  <ng-template ng-option-tmp
    let-item="item">
    {{ (lang$|async) === 'en' ? item.context.name_en : item.label }}
    <span class="badge badge-info ml-1 w-auto"
      *ngIf="item.context.is_group">GROUP</span><br>
    <small>{{ item.context.is_group ? ('MEMOS.MEMBER-CC' | translate) : 'E-mail' }}: {{ item.context.text }}</small>
  </ng-template>
</ng-select>
<div *ngIf="formGroup.controls.ccList.invalid && selectedNgSelect === 'ccList' && submitted"
                            class="text-red pt-1 pl-1">
                            <span *ngIf="formGroup.controls.ccList.errors.required">
                                {{"MEMOS.VALID-CCLIST"|translate}}</span>
                    </div>

<ng-template #certificateStatusTemplate>
  <div class="validity-status"
      [ngSwitch]="certificateStatus">
    <ng-container *ngSwitchCase="'valid'">
      <span class="fas fa-check-circle text-success"></span>
      <label class="text-success">{{ 'CERTIFICATE.VALID' | translate }}</label>
    </ng-container>
    <ng-container *ngSwitchCase="'expired'">
      <span class="fas fa-times-circle text-danger"></span>
      <label class="text-danger">{{ 'CERTIFICATE.EXPIRED' | translate }}</label>
    </ng-container>
    <ng-container *ngSwitchCase="'not_created'">
      <span class="fas fa-times-circle text-danger"></span>
      <label class="text-danger">{{ 'CERTIFICATE.NOT-CREATED' | translate }}</label>
    </ng-container>
  </div>
</ng-template>
<ng-container *ngIf="ddocEnable">
  <app-signing-law-section-radio [signerId]="approval?.person"
      [certStatusSec26]="approval?.m26_certificate_status"
      [certStatusSec28]="approval?.m28_certificate_status"
      [(ddocLawSection)]="ddocLawSection"
      (ddocLawSectionChange)="ddocLawSectionChange.emit($event)"
      [freeLawSelection]="freeLawSelection"
      [allowLOAChange]="allowLOAChange"
      (signerRejected)="approvalChange.emit({ person: null })">
  </app-signing-law-section-radio>
</ng-container>

<div class="p-0 pt-3 d-flex flex-column col-12 col-md-auto"
  *ngIf="resultReservation && approval?.full_name.includes(resultReservation?.business_name)">
  <div *ngFor="let item of resultReservation?.committees; let i = index">
    <div class="mb-2">
      {{i + 1}}.
        <label *ngIf="item.prefix === 'Mr.'">
          {{'MANAGE-CUSTOMER.MR'|translate}}
        </label>
        <label *ngIf="item.prefix === 'Ms.'">
          {{'MANAGE-CUSTOMER.MISS'|translate}}
        </label>
        <label *ngIf="item.prefix === 'Mrs.'">
          {{'MANAGE-CUSTOMER.MRS'|translate}}
        </label>
        <label *ngIf="item.prefix_other">
          {{item.other_prefix}}
        </label>

        <label>{{item.first_name}} {{item.last_name}}</label>&nbsp;
        <label>({{item.email}})</label>
    </div>
  </div>
</div>
