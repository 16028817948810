<ng-template #downloadModal
    let-c="close">
  <app-modal-confirmation>
    <ng-template appCustomModalHeader>
      <div class="modal-header text-center">
        <h4 class="modal-title"
            *ngIf="!isExportGoogle"
            id="modal-basic-title">
          {{ checkPage === 'userPage' ? ('EXPORT.USER-REPORT' | translate) :
          (checkPage === 'dashBoard' ? ('EXPORT.OFFICE-DASHBOARD' | translate) :
          ( title | translate)) }}
        </h4>
        <h4 class="modal-title"
            *ngIf="isExportGoogle"
            id="modal-basic-title">
          {{"TRASH.Export Memo"|translate}}
        </h4>
        <svg-icon src="assets/images/icons/btn-close.svg"
            [svgStyle]="{ 'width.px': 20, 'height.px': 20 ,'margin-top.px':5}"
            class="pointer icon-close"
            (click)="close()"></svg-icon>
      </div>
    </ng-template>
    <ng-template appCustomModalBody>
      <div class="modal-body">
        <div class="col-12 p-0">
          <div class="full-width mx-2">
            <ngb-progressbar [type]="currentStateProgressType"
                [striped]="currentStateProgressType === 'info'"
                [animated]="currentStateProgressType === 'info'"
                [value]="sendingTaskDetail.percent"
                height="2.5rem">
              <div class="font-weight-bold">
                <span class="mb-0 mx-1"
                    *ngIf="sendingTaskDetail._state === celeryImportState.Progress">
                  {{ sendingTaskDetail.percent }}%
                </span>
                <h5 class="mb-0 mx-3 text-white"
                    *ngIf="sendingTaskDetail._state !== celeryImportState.Progress">
                  {{ 'EXPORT.' + sendingTaskDetail._state | translate }}</h5>
              </div>

            </ngb-progressbar>
          </div>

          <div class="full-width mx-2 mt-4"
              *ngIf="sendingTaskDetail._state === celeryImportState.Failure">
            <ngb-alert type="danger"
                [dismissible]="false">
              <h5 class="text-danger">{{ 'EXPORT.ERROR-OCCURRED' | translate }}</h5>
              <div class="frame error p-3"
                  [innerHTML]="sendingTaskDetail.detail"></div>
            </ngb-alert>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template appCustomModalFooter>
      <div class="modal-footer"
          *ngIf="sendingTaskDetail._state ==='SUCCESS' || celeryImportState.Progress">
        <button [style.--c]="themeList?.header_table_color"
            [style.--bg]="'#fff'"
            type="button"
            class="btn btn-outline btn-w-80"
            (click)="c('Close click')">
          {{ "MEMOS.CANCEL" | translate }}
        </button>
        <button appSetTheme
            type="button"
            class="btn btn-w-80"
            [disabled]="!filePath"
            (click)="downloadCSV()">
          {{"EXPORT.DOWNLOAD" | translate }}
        </button>
      </div>
    </ng-template>
  </app-modal-confirmation>
</ng-template>
