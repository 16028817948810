import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/http/api.service';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../../../shared/service/api.constant';
import { Observable } from 'rxjs';
import {
  ChangeLessee,
  ChangeRoom,
  ChangeStatusMemo,
  EarlyCancellation,
  FindPerson,
  NonRenewal,
  Renewal,
  RentAppliances,
  TypeDecisionServiceConfig,
} from './customer-page.models';

@Injectable({
  providedIn: 'root',
})
export class CustomerPageService {
  constructor(
    private http: ApiService,
    private httpClient: HttpClient,
  ) {}

  sendOtpSms(data?, header?) {
    return this.http.post(`/api/custom-sms-otp/send/`, data, header);
  }

  verifyOtpSms(data) {
    return this.http.post(`/api/custom-sms-otp/verify/`, data);
  }

  closeOtpSms(data) {
    return this.http.post(`/api/custom-sms-otp/close/`, data);
  }

  getIndividualRegister(id?) {
    return this.http.get(`/api/add-individual-person/${id}`);
  }

  saveIndividualRegister(data) {
    return this.http.post(`/api/add-individual-person/`, data);
  }

  updateIndividualRegister(data, id?) {
    return this.http.patch(`/api/add-individual-person/${id}/`, data);
  }

  getCompanyRegister(id?) {
    return this.http.get(`/api/add-company/${id}`);
  }

  updateCompanyRegister(data, id?) {
    return this.http.patch(`/api/add-company/${id}/`, data);
  }

  verifyCompany(data) {
    return this.http.post(`/api/add-company/verify/`, data);
  }

  saveCompanyRegister(data) {
    return this.http.post(`/api/add-company/`, data);
  }

  sendSmsOTPInformationCheck(data) {
    return this.http.post(`/api/form-landing/check-active/`, data);
  }

  sendSmsOTPInformationCheckWithHeader(data, header) {
    return this.httpClient.post(
      environment.baseUrl + `/api/form-landing/check-active/`,
      data,
      header,
    );
  }

  verifyOTPRequestForm(data) {
    return this.httpClient.post(
      environment.baseUrl + `/api/form-landing/verify/`,
      data,
    );
  }

  verifyOTPRequestFormWithHeader(data, header) {
    return this.http.post(`/api/form-landing/verify/`, data, header);
  }

  getReservePerson(header, params?) {
    return this.httpClient.get(
      environment.baseUrl + `/api/reserve-person/`,
      {
        params,
        headers: header,
      },
    );
  }

  saveNonRenewalMemos(header, data): Observable<NonRenewal> {
    return this.httpClient.post<NonRenewal>(
      environment.baseUrl + `/api/non-renewal-memo/`,
      data,
      {
        headers: header,
      },
    );
  }

  saveTerminateContractMemos(
    header,
    data,
  ): Observable<EarlyCancellation> {
    return this.httpClient.post<EarlyCancellation>(
      environment.baseUrl + `/api/early-cancellation-memo/`,
      data,
      {
        headers: header,
      },
    );
  }
  saveNoneRenewalEvidenceFile(header, data): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.httpClient
        .post(
          environment.baseUrl + `/api/non-renewal-evidence/`,
          data,
          {
            headers: header,
          },
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  saveTerminateContractEvidenceFile(header, data): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.httpClient
        .post(
          environment.baseUrl + `/api/early-cancellation-evidence/`,
          data,
          {
            headers: header,
          },
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  saveMemoMultipleAttachments(header, data): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      return this.http
        .post(API_URL.memo_bulk_attachment, data)
        .subscribe({
          next: () => {
            resolve();
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  saveChangeRentAppliances(header, data): Observable<RentAppliances> {
    return this.httpClient.post<RentAppliances>(
      environment.baseUrl + `/api/request-appliance-memo/`,
      data,
      {
        headers: header,
      },
    );
  }

  saveChangeRoomStatus(header, data): Observable<ChangeStatusMemo> {
    return this.httpClient.post<ChangeStatusMemo>(
      environment.baseUrl + `/api/change-status-memo/`,
      data,
      {
        headers: header,
      },
    );
  }

  saveRenewalMemos(header, data): Observable<Renewal> {
    return this.httpClient.post<Renewal>(
      environment.baseUrl + `/api/renewal-memo/`,
      data,
      {
        headers: header,
      },
    );
  }

  findPersonChangeLessee(header, data): Observable<FindPerson> {
    return this.httpClient.post<FindPerson>(
      environment.baseUrl + `/api/find-person-change-lessee/`,
      data,
      {
        headers: header,
      },
    );
  }

  saveChangeLesseeAfterFindPerson(
    header,
    data,
  ): Observable<ChangeLessee> {
    return this.httpClient.post<ChangeLessee>(
      environment.baseUrl + `/api/change-lessee-memo/`,
      data,
      {
        headers: header,
      },
    );
  }

  getCalculateEndDate(header, data): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<string[]>(
          environment.baseUrl + `/api/calculate-end-date/`,
          data,
          {
            headers: header,
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  saveChangeRoom(header, data): Observable<ChangeRoom> {
    return this.httpClient.post<ChangeRoom>(
      environment.baseUrl + `/api/change-room-memo/`,
      data,
      {
        headers: header,
      },
    );
  }

  configurationDecision(
    rentalDetails,
    items,
    extraParams?,
  ): TypeDecisionServiceConfig {
    return {
      renew: {
        params: {
          memo_number: rentalDetails?.memo_number,
          years: items.selectDate,
          new_end_date: items.selectNewDate,
          note: items.note,
        },
        save_memo_service: (header, data) =>
          this.saveRenewalMemos(header, data),
      },
      not_renew: {
        params: {
          memo_number: rentalDetails?.memo_number,
          reason: items.selectReason,
          reason_other: items.reasonOther,
        },
        save_memo_service: (header, data) =>
          this.saveNonRenewalMemos(header, data),
      },
      early_cancellation: {
        params: {
          memo_number: rentalDetails?.memo_number,
          terminate_date: items.terminateDate,
          reason: items.selectReason,
          reason_other: items.reasonOther,
        },
        save_memo_service: (header, data) =>
          this.saveTerminateContractMemos(header, data),
      },
      change_lessee: {
        params: {
          person_type: items.person_type,
          email: items.email,
          phone_number: items.phone,
        },
        save_memo_service: (header, data) =>
          this.findPersonChangeLessee(header, data),
      },
      change_room: {
        params: {
          memo_number: rentalDetails?.memo_number,
          reason: items.selectReason,
          reason_other: items.reasonOther,
        },
        save_memo_service: (header, data) =>
          this.saveChangeRoom(header, data),
      },
      change_status: {
        params: {
          memo_number: extraParams?.agreement_memo_number,
          reason: items.selectReason,
          remark: items.note,
        },
        save_memo_service: (header, data) =>
          this.saveChangeRoomStatus(header, data),
      },
      request_appliance: {
        params: {
          person_type: extraParams.register_type,
          sub_person_id: extraParams.register_id,
          memo_number:
            extraParams.agreement_contract_number ||
            rentalDetails?.memo_number,
          reason: items.selectReason,
          reason_other: items.reasonOther,
          details: items.stepperCheckbox
            .filter((item) => item.quantity > 0)
            .map((detail) => {
              return {
                purpose: detail.value,
                quantity: detail.quantity,
              };
            }),
        },
        save_memo_service: (header, data) =>
          this.saveChangeRentAppliances(header, data),
      },
    };
  }
}
