export const TYPE_COMPANY = [
  {
    value: 'limited_company',
    label_en: 'Limited Company',
    label: 'บริษัทจำกัด',
  },
  {
    value: 'public_limited_company',
    label_en: 'Public Limited Company',
    label: 'บริษัทมหาชนจำกัด',
  },
  {
    value: 'limited_partnership',
    label_en: 'Partnership',
    label: 'ห้างหุ้นส่วนจำกัด',
  },
  {
    value: 'other',
    label_en: 'Others',
    label: 'อื่นๆ',
  },
];

export const PREFIX = [
  {
    value: 'Mr.',
    label_en: 'Mr.',
    label: 'นาย',
  },
  {
    value: 'Mrs.',
    label_en: 'Mrs.',
    label: 'นาง',
  },
  {
    value: 'Ms.',
    label_en: 'Ms.',
    label: 'นางสาว',
  },
  {
    value: 'other',
    label_en: 'Others',
    label: 'อื่นๆ',
  },
];

export const JOB_LIST = [
  {
    value: 'business',
    label_en: 'Business Owner/Self-employed',
    label: 'เจ้าของธุรกิจ/ประกอบอาชีพอิสระ',
  },
  {
    value: 'freelancer',
    label_en: 'Freelancer/Consultant',
    label: 'ฟรีแลนซ์/ที่ปรึกษา',
  },
  {
    value: 'government',
    label_en: 'Government',
    label: 'งานราชการ/รัฐวิสาหกิจ',
  },
  {
    value: 'part_time',
    label_en: 'Part-time/Casual',
    label: 'พนักงานพาร์ทไทม์',
  },
  {
    value: 'professional',
    label_en: 'Professional',
    label: 'พนักงานบริษัทเอกชน',
  },
  {
    value: 'retiree',
    label_en: 'Retiree',
    label: 'เกษียณอายุ',
  },
  {
    value: 'student',
    label_en: 'Student',
    label: 'นักเรียน',
  },
  {
    value: 'other',
    label_en: 'Others (please specify)',
    label: 'อื่นๆ (โปรดระบุ)',
  },
  {
    value: 'undisclosed',
    label_en: 'Undisclosed',
    label: 'ไม่เปิดเผย',
  },
];

export const NUMBEROFRESIDENT_LIST = [
  {
    value: 'one',
    label_en: '1 Person',
    label: '1 ท่าน',
  },
  {
    value: 'two',
    label_en: '2 Person',
    label: '2 ท่าน',
  },
  {
    value: 'more_than_two',
    label_en: 'More than 2',
    label: 'มากกว่า 2 ท่าน',
  },
];

export const CHANNEL_LIST = [
  {
    value: 'billboard',
    label_en:
      'Advertising signs, accommodation signs for rent in the area',
    label: 'ป้ายโฆษณา ป้ายที่พักให้เช่า ในพื้นที่',
  },
  {
    value: 'friend',
    label_en:
      'From the advice of friends, acquaintances, old customers',
    label: 'จากคำแนะนำของเพือน คนรู้จัก ลูกค้าเก่า',
  },
  {
    value: 'google',
    label_en: 'Google and from various accommodation sites',
    label: 'google และจากเว็บไซต์หาที่พักต่างๆ',
  },
  {
    value: 'facebook',
    label_en: 'Facebook pages',
    label: 'Facebook เพจต่างๆ',
  },
  {
    value: 'agency',
    label_en: 'From the advice of an agency or broker',
    label: 'จากคำแนะนำ Agency หรือ นายหน้า',
  },
  {
    value: 'other',
    label_en: 'Others',
    label: 'อื่นๆ',
  },
];

export const FULL_DATE = [
  { th: 'มกราคม', en: 'January', short: '01' },
  { th: 'กุมภาพันธ์', en: 'February', short: '02' },
  { th: 'มีนาคม', en: 'March', short: '03' },
  { th: 'เมษายน', en: 'April', short: '04' },
  { th: 'พฤษภาคม', en: 'May', short: '05' },
  { th: 'มิถุนายน', en: 'June', short: '06' },
  { th: 'กรกฎาคม', en: 'July', short: '07' },
  { th: 'สิงหาคม', en: 'August', short: '08' },
  { th: 'กันยายน', en: 'September', short: '09' },
  { th: 'ตุลาคม', en: 'October', short: '10' },
  { th: 'พฤศจิกายน', en: 'November', short: '11' },
  { th: 'ธันวาคม', en: 'December', short: '12' },
];
